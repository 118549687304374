import { Button, Icon, Text } from ".";
import { colors, spacing } from "app/utils/theme";

import MuiDropdown from "./MuiDropdown";
import { get } from "lodash";
import styled from "styled-components";
import { useState } from "react";

const SimpleDropdown = ({ data }) => {
  const darkMode = get(data, "darkMode");

  const [expanded, setExpanded] = useState(false);

  const [closeCounter, setCloseCounter] = useState(0);

  const showOnHover = false;

  const noIcon = get(data, "noIcon");

  const optionFontStyle = get(data, "optionFontStyle", "bodyMd");

  const getTriggerComponent = () => {
    if (data.component) {
      return data.component;
    }

    if (data.text) {
      return <Text data={data.text} />;
    }

    if (data.icon) {
      return <Icon data={data.icon} />;
    }

    if (data.button) {
      return (
        <Button
          data={{
            ...data.button,
            icon:
              data.button.icon || (expanded ? "FiChevronUp" : "FiChevronDown"),
          }}
        />
      );
    }
  };

  return (
    <Container
      onMouseEnter={() => {
        if (showOnHover) {
          setExpanded(true);
        }
      }}
      onMouseLeave={() => {
        if (showOnHover) {
          setExpanded(false);
        }
      }}
    >
      <MuiDropdown
        triggerComponent={getTriggerComponent()}
        setExpanded={setExpanded}
        closeCounter={closeCounter}
        background={darkMode ? colors.darkModeInputBackground : "white"}
        style={{ borderRadius: "100px" }}
      >
        <OptionsContainer>
          {data.options.map((option, i) => {
            const icon = get(option, ["data", "icon"]) || get(option, "icon");
            return (
              <Option
                id={`simpleDropdown-option-${i}`}
                key={i}
                darkMode={darkMode}
                background={
                  darkMode
                    ? colors.darkModeLightBackground
                    : option.active && colors.grey2
                }
                padding={data.optionPadding}
                minWidth={data.optionMinWidth}
                onClick={(e) => {
                  option.onClick(e);
                  setCloseCounter(closeCounter + 1);
                }}
              >
                {icon && !noIcon && (
                  <Icon
                    data={{
                      icon,
                      color: darkMode ? "white" : colors.grey4,
                      size: 18,
                      hover: true,
                    }}
                  />
                )}
                <Text
                  data={{
                    text: option.label,
                    cursor: "pointer",
                    whiteSpace: "nowrap",
                    fontStyle: optionFontStyle,
                    color: darkMode ? "white" : colors.default,
                  }}
                />
              </Option>
            );
          })}
        </OptionsContainer>
      </MuiDropdown>
    </Container>
  );
};

export default SimpleDropdown;

const Container = styled.div`
  position: relative;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 8px;
`;

const Option = styled.div`
  font-size: 16px;
  padding: 10px 12px 10px 12px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  border-radius: 8px;
  min-width: ${(p) => p.minWidth || "fit-content"};
  align-items: center;
  background: ${(p) => p.background};
  &:hover {
    background: ${(p) =>
      p.darkMode ? colors.darkModeInputBackground : colors.clickUpBackground};
  }
`;
