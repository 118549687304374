import { isFrontlyAdmin, safeArray } from "app/utils/utils";

import { get } from "lodash";

// This function handles finding the related dropdown fields which are formatted on the back-end
export const getFormDataWithRelatedFields = (data) => {
  const { block, app, spreadsheets, formData } = data;

  let newFormData = { ...formData };

  safeArray(app, "data_relations")
    .filter((r) => r.sheet2 === get(block, "spreadsheet"))
    .forEach((r) => {
      const concatId = `${block.id}-${r.id}`;

      const m = isFrontlyAdmin
        ? []
        : get(spreadsheets, ["relations", concatId], []);

      const match = m.find(
        (i) => get(i, r.column1) === get(formData, r.column2)
      );

      if (match) {
        Object.keys(match).forEach((k) => {
          if (!["frontly_id", "frontly_data"].includes(k)) {
            newFormData[`${r.column2}__${k}`] = match[k];
          }
        });
      }
    });

  return newFormData;
};

// Get relational data columns from the block
export const getRelationColumnsFromBlock = (data) => {
  const { block, app, spreadsheets } = data;

  let relationColumns = {};
  safeArray(app, "data_relations")
    .filter((r) => r.sheet2 === get(block, "spreadsheet"))
    .forEach((r) => {
      const concatId = `${block.id}-${r.id}`;

      const m = isFrontlyAdmin
        ? []
        : get(spreadsheets, ["relations", concatId], []);

      relationColumns[r.column2] = m;
    });

  return { relationColumns };
};
