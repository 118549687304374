import {
  cloneDeep,
  get,
  isArray,
  isEqual,
  isInteger,
  isNaN,
  isNil,
  isString,
  startsWith,
  uniq,
} from "lodash";

import Cookies from "js-cookie";
import { colors } from "./theme";
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
import moment from "moment";

export const publicRoutes = [
  "/",
  "/login",
  "/signup",
  "/fullscreenform",
  "/v1signup",
  "/app_templates",
  "/app_templates/:id",
  "/appsumo_signup",
  "/experts",
  "/expert/:id",
  "/thank-you-ltd",
];

export function getPreviousSteps(actions, actionId) {
  // Create a map for easy access to each action by its ID
  const actionMap = new Map(actions.map((action) => [action.id, action]));

  // Function to get all ancestors of a given action
  function getAncestors(actionId) {
    const action = actionMap.get(actionId);
    if (!action || !action.parent) {
      return [];
    }
    // Get the parent and recursively get its ancestors
    const parentAncestors = getAncestors(action.parent);
    return [action.parent, ...parentAncestors];
  }

  return getAncestors(actionId);
}

const getMatchingLayouts = (initialBlocks) => {
  let blocks = [];

  for (let b of initialBlocks) {
    let minWidth = 0;
    let maxWidth = 0;
    let minHeight = 100;

    if (
      ["Table", "Grid", "Kanban", "Calendar", "Form", "Map"].includes(
        b.componentId
      )
    ) {
      minWidth = 50;
      maxWidth = 100;
      minHeight = 100;
    }

    if (["Chart", "Stat"].includes(b.componentId)) {
      minWidth = 33;
      maxWidth = 50;
      minHeight = 33;
    }

    blocks.push({
      id: b.id,
      componentId: b.componentId,
      min_width: minWidth,
      max_width: maxWidth,
      min_height: minHeight,
      layoutParent: null,
    });
  }

  let layouts = [
    {
      id: 8,
      slots: [
        { id: 1, width: 50, height: 100 },
        { id: 2, width: 50, height: 100 },
      ],
    },
    {
      id: 9,
      slots: [
        { id: 1, width: 33, height: 100 },
        { id: 2, width: 33, height: 100 },
        { id: 3, width: 33, height: 100 },
      ],
    },
    {
      id: 4,
      slots: [
        { id: 1, width: 50, height: 100, require_large: true },
        { id: 2, width: 50, height: 50 },
        { id: 3, width: 50, height: 50 },
      ],
    },
    {
      id: 10,
      slots: [
        { id: 1, width: 50, height: 100, require_large: true },
        { id: 2, width: 50, height: 33 },
        { id: 3, width: 50, height: 33 },
        { id: 4, width: 50, height: 33 },
      ],
    },
  ];

  let matchingLayouts = [];

  let bigBlocks = blocks.filter((b) =>
    ["Table", "Grid", "Kanban", "Calendar", "Form", "Map"].includes(
      b.componentId
    )
  );
  let smallBlocks = blocks.filter((b) =>
    ["Chart", "Stat"].includes(b.componentId)
  );

  let sortedBlocks = bigBlocks.concat(smallBlocks);

  for (let layout of layouts) {
    let slots = layout.slots || [];

    let matchedBlocks = [];
    let matchedSlots = [];
    let matchMap = {};

    for (let slot of slots) {
      for (let block of sortedBlocks) {
        let blockId = block.id;

        if (
          block.min_width <= slot.width &&
          block.max_width >= slot.width &&
          block.min_height <= slot.height &&
          ((block.componentId !== "Chart" && block.componentId !== "Stat") ||
            !slot.require_large)
        ) {
          if (
            !matchedBlocks.includes(blockId) &&
            !matchedSlots.includes(slot.id)
          ) {
            matchedSlots.push(slot.id);
            matchedBlocks.push(blockId);
            matchMap[blockId] = {
              layoutPosition: slot.id,
              layoutParent: layout.id,
            };
          }
        }
      }
    }

    let unmatchedBlocks = blocks
      .filter((b) => !matchedBlocks.includes(b.id))
      .map((b) => b.id);

    if (matchedBlocks.length === slots.length) {
      matchingLayouts.push({
        layout_id: layout.id,
        match_map: matchMap,
        unmatched_blocks: unmatchedBlocks,
      });
    }
  }

  let unmatched = null;
  let obj = null;

  for (let m of matchingLayouts) {
    const unmatchedBlocks = get(m, "unmatched_blocks", []);

    let count = unmatchedBlocks.length;
    if (unmatched === null || count < unmatched) {
      unmatched = count;
      obj = m;
    }
  }

  return obj;
};

export const frontlyLogoDark =
  "https://res.cloudinary.com/frontly/image/upload/v1712695679/frontly-darker-blue-apr-2024_eqpe2j.png";

// Not currently used
// Function that takes a block ID and finds the top parent block ID
// export const findTopParentBlock = (blockId, blocks) => {
//   let currentBlock = blocks.find((block) => block.id === blockId);
//   let topParentBlockId = blockId;

//   while (currentBlock.parent || currentBlock.layoutParent) {
//     topParentBlockId = currentBlock.parent || currentBlock.layoutParent;
//     currentBlock = blocks.find((block) => block.id === topParentBlockId);
//   }

//   return blocks.find((block) => block.id === topParentBlockId);
// };

// Write a function that takes a block and a parent block and returns true if the block is a child of the parent block, at any level
export const isBlockChildOf = (block, parentBlock, blocks) => {
  if (!block.layoutParent) {
    return false;
  }

  if (block.layoutParent === parentBlock.id) {
    return true;
  }

  const parentBlockOfBlock = blocks.find((b) => b.id === block.layoutParent);

  return isBlockChildOf(parentBlockOfBlock, parentBlock, blocks);
};

// For handling when replacing a placeholder block with another block
export const getBlocksWithPlaceholder = (blocks, placeholderId, newBlockId) => {
  const matchingNewBlock = blocks.find((b) => b.id === newBlockId);

  return (
    blocks
      // Filter out the new block
      .filter((b) => b.id !== newBlockId)
      .map((b) => {
        if (b.id === placeholderId) {
          let blockCopy = { ...matchingNewBlock };
          ["layoutWidth", "height", "fillRemainingSpace"].forEach((i) => {
            blockCopy[i] = get(b, i);
          });
          return blockCopy;
        }

        return b;
      })
  );
};

export const getInputBackgroundColor = (data) => {
  const { darkMode, disabled } = data;
  if (disabled) {
    return darkMode ? colors.darkModeGrey : colors.grey1;
  } else {
    return darkMode ? colors.darkModeInputBackground : "white";
  }
};

export const getAllBlocksBeneath = (blockId, blocks) => {
  const childBlocks = blocks.filter(
    (block) => block.parent === blockId || block.layoutParent === blockId
  );

  let allDescendantBlocks = [];

  // Iterate over each child and recursively find their children
  childBlocks.forEach((child) => {
    allDescendantBlocks.push(child.id);
    const descendants = getAllBlocksBeneath(child.id, blocks);
    allDescendantBlocks = allDescendantBlocks.concat(descendants);
  });

  return allDescendantBlocks;
};

export const isValidJson = (inputJson) => {
  try {
    JSON.parse(inputJson);
    return true;
  } catch (error) {
    return false;
  }
};

export const cssStringToObject = (cssString) => {
  if (!cssString || !isString(cssString)) {
    return {};
  }

  const cssObject = {};
  const cssRules = cssString
    .split(";")
    .map((rule) => rule.trim())
    .filter((rule) => rule);

  cssRules.forEach((rule) => {
    const [property, value] = rule.split(":").map((part) => part.trim());
    if (property && value) {
      const camelCaseProperty = property.replace(/-([a-z])/g, (g) =>
        g[1].toUpperCase()
      );
      cssObject[camelCaseProperty] = value;
    }
  });

  return cssObject;
};

export const validateCSS = (cssString) => {
  // disabling validation for now, but maybe later we can add it
  return cssString;

  if (!cssString || !isString(cssString)) {
    return "";
  }

  // Split the CSS string by lines
  const lines = cssString.trim().split(/\n/);

  // Regular expression to match a basic CSS property pattern: "property: value;"
  // Allows for optional whitespace around property names and values
  const cssPattern = /^\s*[a-zA-Z-]+\s*:\s*[^;]+;\s*$/;

  for (let line of lines) {
    // Check if each line matches the CSS pattern
    if (!cssPattern.test(line)) {
      return ""; // Return false if any line doesn't match
    }
  }

  // If all lines match the pattern, return true
  return cssString;
};

export const operatorLabelMap = {
  // Equals
  equals: "Equals",
  does_not_equal: "Does Not Equal",
  contains: "Contains",
  in: "In",
  not_in: "Not In",
  does_not_contain: "Does Not Contain",
  // Less / more
  greater_than: "Greater Than",
  less_than: "Less Than",
  length_greater_than: "Length Greater Than",
  length_less_than: "Length Less Than",
  // Booleans
  is_true: "Is True (boolean)",
  is_false: "Is False (boolean)",
  exists: "Exists (has value)",
  does_not_exist: "Does Not Exist (no value)",
  // Dates
  date_equals: "Date Equals",
  date_after: "Date After",
  date_before: "Date Before",
  date_in_range: "Date In Range",
  // New type-specific operators
  string_equals_string: "String Equals String",
  array_contains_string: "Array Contains String",
  string_contains_string: "String Contains String",
  string_in_array: "String In Array",
  string_in_string: "String In String",
  string_not_in_string: "String Not In String",
  string_not_in_array: "String Not In Array",
  array_does_not_contain_string: "Array Does Not Contain String",
  string_does_not_contain_string: "String Does Not Contain String",
};

export const operators = [
  // New type-specific operators
  "array_contains_string",
  "array_does_not_contain_string",
  "string_contains_string",
  "string_does_not_contain_string",
  "string_in_array",
  "string_in_string",
  "string_not_in_array",
  "string_not_in_string",

  "equals",
  "does_not_equal",
  "greater_than",
  "less_than",
  "contains",
  "in",
  "not_in",
  "length_greater_than",
  "is_true",
  "is_false",
  "length_less_than",
  "does_not_contain",
  "date_equals",
  "date_after",
  "date_before",
  "date_in_range",
  "exists",
  "does_not_exist",
];

export const asyncActionTypes = [
  "MAKE",
  "WEBHOOK",
  "OPEN_AI",
  "AI_GENERATE_RECORDS",
  "ZAPIER",
];

export const getBlockContentHeight = (
  activeApp,
  block,
  items,
  resultsPerPage,
  mod = 0
) => {
  const styling = get(activeApp, "styling");

  // Find height of available container for grid layout
  const inGridLayout = get(block, "inGridLayout");
  let height = get(block, "gridHeight");

  return inGridLayout ? getPixels(height + mod) : "auto";
};

export function formatNumber(num, decimals) {
  if (isNaN(decimals)) {
    return num;
  }

  const decimalsInt = parseInt(decimals, 0);

  if (!decimalsInt || decimalsInt === 0) {
    return num;
  }

  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(num);
}

export const lightenColor = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const safeString = (str) => {
  if (str === null || str === undefined) {
    return "";
  }

  return str.toString();
};

export const safeToFixed = (value, decimals) => {
  if (!value) {
    const num = 0;
    return num.toFixed(decimals);
  }

  try {
    return parseFloat(value).toFixed(decimals);
  } catch (error) {
    const num = 0;
    return num.toFixed(decimals);
  }
};

export const safeArray = (obj, key = null) => {
  let potentialArray = obj;

  if (key) {
    potentialArray = get(obj, key, []);
  }

  if (isArray(potentialArray)) {
    return potentialArray;
  }

  return [];
};

// Check if a block has a parent and if so, use that detail view mode
export const getDetailViewMode = (block, blocks) => {
  const parentBlock = blocks.find((b) => b.id === get(block, "parent"));
  const parentDetailMode = get(parentBlock, "detailViewMode");
  const detailViewMode = block && get(block, "detailViewMode", "modal");
  return parentDetailMode || detailViewMode;
};

const detectInputType = (input) => {
  // Regular expression pattern to detect an email
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Regular expression pattern to detect a URL
  const urlPattern =
    /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;

  if (emailPattern.test(input)) {
    return "email";
  } else if (urlPattern.test(input)) {
    return "url";
  } else {
    return "neither";
  }
};

function ensureHttps(url) {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  } else {
    return "https://" + url;
  }
}

export const getLinkOnClick = (value) => {
  const textPattern = detectInputType(value);

  if (textPattern !== "neither") {
    if (textPattern === "url") {
      return () => window.open(ensureHttps(value), "blank");
    } else {
      const emailLink = `mailto:${value}`;
      return () => window.open(emailLink, "blank");
    }
  }

  return null;
};

export const getBlockLabel = (b) => {
  if (b.componentId === "AIBlock") {
    return "AI Block";
  }

  if (b.componentId === "Custom") {
    return b.label || "Custom";
  }

  if (["Text", "Button"].includes(b.componentId)) {
    return truncateText(b.text, 20);
  }
  if (b.label) {
    return b.label;
  }
};

export const parseDateWithFormatObject = (data) => {
  const {
    value,
    formatObject = null,
    returnMoment = false,
    returnNativeDate = false,
  } = data;

  const formatType = get(formatObject, "formatType");
  const customFormat =
    formatType === "custom" ? get(formatObject, "customFormat") : null;

  let inputDate = get(formatObject, "inputDate");

  if (!inputDate) {
    inputDate = detectDateFormat(value);
  }

  const inputTime = get(formatObject, "inputTime");
  const showAmPm = inputTime && get(formatObject, "showAmPm");

  let format = inputDate;

  if (inputTime) {
    format = `${inputDate} ${inputTime}`;

    if (!showAmPm) {
      // If not showing AM/PM, convert to 24 hour format
      format = format.replace("hh", "HH").replace("h", "HH");
    }
  }

  if (customFormat) {
    format = customFormat;
  }

  let dateMoment = null;

  if (moment.isMoment(value)) {
    // Check if the date input is already a moment object
    dateMoment = value;
  }

  // Check if the date input is a Date object
  else if (!dateMoment && value instanceof Date) {
    dateMoment = moment(value);
  }

  // If the date input is a string, use the provided format
  else if (!dateMoment && typeof value === "string") {
    dateMoment = moment(value, format);
  }

  if (dateMoment && !dateMoment.isValid()) {
    return "";
  }

  if (dateMoment) {
    if (returnNativeDate) {
      return dateMoment.toDate();
    }

    if (returnMoment) {
      return dateMoment;
    }

    // Default to inputdate format if not set explicitly
    const outputDate = get(formatObject, "outputDate") || inputDate;
    const outputTime = get(formatObject, "outputTime") || inputTime;

    if (outputDate === "relative") {
      // Relative
      return dateMoment.fromNow();
    } else {
      // Standard

      let finalFormat = outputDate;

      if (customFormat) {
        finalFormat = customFormat;
      } else {
        if (outputTime && outputTime !== "none") {
          finalFormat += ` ${outputTime}`;

          if (!showAmPm) {
            // If not showing AM/PM, convert to 24 hour format
            finalFormat = finalFormat.replace("hh", "HH").replace("h", "HH");
          }
        }

        if (showAmPm) {
          finalFormat += ` a`;
        }
      }
      return dateMoment.format(finalFormat);
    }
  }

  return dateMoment;
};

export const defaultTrue = (value) => (isNil(value) ? true : value);

export const getDisplayHeaders = (headers) =>
  headers.filter((h) => !["frontly_data", "frontly_id"].includes(h));

export const sortItems = (items, listKey, sortingOrder, sortingKey) => {
  let newItems = [];
  sortingOrder.forEach((orderItem) => {
    const sortKeyValue = get(orderItem, sortingKey);
    const match = items.find((i) => get(i, listKey) === sortKeyValue);
    if (match) {
      newItems.push({ ...match, ...orderItem });
    }
  });

  const orderedIds = newItems.map((i) => get(i, listKey));

  items
    .filter((i) => !orderedIds.includes(get(i, listKey)))
    .forEach((item) => {
      newItems.push(item);
    });
  return newItems;
};

export const allBlocks = [
  // {
  //   type: "Chatbot",
  //   icon: "FiMessageSquare",
  //   description:
  //     "Allow users to engage in a chat conversation with AI based on context you provide",
  //   card: "on",
  //   hideLabel: true,
  //   category: "ai",
  //   defaultLayoutWidth: 600,
  // },
  // {
  //   type: "Form2",
  //   icon: "FiClipboard",
  //   description: "Edit spreadsheet data in a form with custom input types",
  //   card: "on",
  //   category: "form",
  // },
  {
    type: "Custom",
    icon: "FiRefreshCcw",
    description: "A reusable custom block",
    card: "off",
    category: "custom",
  },
  {
    type: "Table",
    icon: "HiOutlineTableCells",
    description: "Display data in a standard table with sorting and filters",
    requiresSheet: true,
    card: "on",
    category: "dataDisplay",
    gridWidth: 4,
  },
  {
    type: "Form",
    icon: "FiClipboard",
    description: "Edit spreadsheet data in a form with custom input types",
    requiresSheet: true,
    card: "on",
    category: "form",
  },
  {
    type: "Grid",
    icon: "FiGrid",
    description: "Display data in a grid of cards with images and text",
    requiresSheet: true,
    card: "off",
    category: "dataDisplay",
    gridWidth: 4,
  },
  {
    type: "Kanban",
    icon: "FiTrello",
    description: "Track your progress with this card-based workflow board",
    requiresSheet: true,
    card: "off",
    category: "dataDisplay",
    gridWidth: 4,
  },
  {
    type: "Calendar",
    icon: "FiCalendar",
    description: "Display one or more events with image & description",
    requiresSheet: true,
    card: "on",
    category: "dataDisplay",
    gridWidth: 4,
  },
  // {
  //   type: "Chart2",
  //   icon: "FiPieChart",
  //   description:
  //     "Visualize & explore your data to exhibit patterns and relationships",
  //   defaults: [
  //     { id: "showCreate", value: false },
  //     { id: "showSearch", value: false },
  //   ],
  //   requiresSheet: true,
  //   card: "on",
  //   category: "analytics",
  // },
  {
    type: "Chart",
    icon: "FiPieChart",
    description:
      "Visualize & explore your data to exhibit patterns and relationships",
    defaults: [
      { id: "showCreate", value: false },
      { id: "showSearch", value: false },
    ],
    requiresSheet: true,
    card: "on",
    category: "analytics",
  },
  {
    type: "Stat",
    icon: "TbSquareNumber1",
    description: "Display a calculated metric based on your spreadsheet data",
    requiresSheet: true,
    card: "on",
    category: "analytics",
  },
  {
    type: "Text",
    icon: "FiType",
    description: "Insert dynamic text with custom click actions",
    card: "off",
    hideLabel: true,
    category: "basics",
  },
  {
    type: "Icon",
    icon: "FiCompass",
    description: "Add an icon with custom click actions",
    card: "off",
    hideLabel: true,
    category: "basics",
    gridWidth: 4,
  },
  {
    type: "RichText",
    icon: "FiType",
    description:
      "Create rich multi-line text with font sizes and other formatting",
    card: "off",
    hideLabel: true,
    category: "basics",
  },
  {
    type: "Button",
    icon: "FiMousePointer",
    description: "Add text and an icon with custom click actions",
    card: "disabled",
    category: "basics",
    gridWidth: 4,
  },
  {
    type: "Progress",
    icon: "RxSlider",
    description: "Display a progress bar with custom colors and values",
    card: "disabled",
    hideLabel: true,
    category: "basics",
    gridWidth: 4,
  },
  {
    type: "Menu",
    icon: "FiMoreHorizontal",
    description: "A custom dropdown menu with multiple actions",
    card: "disabled",
    category: "basics",
    gridWidth: 4,
  },
  {
    type: "Map",
    icon: "FiMapPin",
    description: "Embed a Google map in your app",
    requiresSheet: true,
    card: "disabled",
    category: "dataDisplay",
  },
  {
    type: "Timeline",
    icon: "FiGitCommit",
    description: "Display and celebrate your milestones on this vertical chart",
    requiresSheet: true,
    card: "on",
    category: "dataDisplay",
  },
  {
    type: "InfoList",
    icon: "FiList",
    description: "Display a single record as a list of labels and values",
    requiresSheet: true,
    card: "on",
    category: "dataDisplay",
  },
  {
    type: "Row",
    icon: "Custom-RowIcon",
    description: "Organize blocks in a horizontal row",
    card: "off",
    padding: { all: "0" },
    hideLabel: true,
    category: "layout",
  },
  {
    type: "Column",
    icon: "Custom-ColumnIcon",
    description: "Organize blocks in a vertical column",
    card: "off",
    hideLabel: true,
    category: "layout",
    padding: { all: "0" },
  },
  {
    type: "Image",
    icon: "FiImage",
    description: "Display an image in your app",
    card: "disabled",
    category: "media",
  },
  {
    type: "Video",
    icon: "FiYoutube",
    description: "Display a YouTube video in your app",
    card: "disabled",
    category: "media",
  },
  {
    type: "Iframe",
    icon: "FiSquare",
    description: "Embed external content inside your Frontly app",
    card: "off",
    hideLabel: true,
    category: "media",
  },
  {
    type: "QuoteCalculator",
    icon: "BsCalculator",
    description: "Create a simple quote calculator with your Google Sheet",
    requiresSheet: true,
    card: "on",
    category: "other",
    gridWidth: 4,
  },
  {
    type: "AIBlock",
    icon: "HiSparkles",
    description:
      "Allow users to interact directly with their data conversationally",
    card: "on",
    hideLabel: true,
    category: "other",
  },
  {
    type: "Input",
    icon: "RxInput",
    description: "A standalone text input field",
    card: "off",
    hideLabel: true,
    category: "form",
    defaults: [{ id: "fillRemainingSpace", value: true }],
  },
  {
    type: "TextArea",
    icon: "RxInput",
    description: "A standalone text area input field",
    card: "off",
    hideLabel: true,
    category: "form",
    defaults: [{ id: "fillRemainingSpace", value: true }],
  },
  {
    type: "Select",
    icon: "RxDropdownMenu",
    description: "A standalone select dropdown field",
    card: "off",
    hideLabel: true,
    category: "form",
    defaults: [{ id: "fillRemainingSpace", value: true }],
  },
  {
    type: "Switch",
    icon: "RxSwitch",
    description: "A standalone switch input field",
    card: "off",
    hideLabel: true,
    category: "form",
  },
  {
    type: "Placeholder",
    icon: "FiSquare",
    description: "A simple placeholder for building advanced layouts",
    card: "disabled",
    category: "layout",
  },
  // DEPRECATED
  {
    type: "TextButtonRow",
    icon: "FiMoreHorizontal",
    description: "A text label and a row of multiple buttons",
    card: "disabled",
  },
  {
    type: "Layout",
    icon: "FiLayout",
    description: "Organize blocks in a pre-defined layout",
    card: "off",
    hideLabel: true,
    category: "layout",
  },
  // ------
];

export const sortSections = (sections, sectionOrder) => {
  const orderedSections = sections
    .filter((section) => sectionOrder.includes(section))
    .sort((a, b) => sectionOrder.indexOf(a) - sectionOrder.indexOf(b));

  const undefinedSections = sections.filter(
    (section) => !sectionOrder.includes(section)
  );

  return orderedSections.concat(undefinedSections);
};

export const getDateFormatString = (format) => {
  const formatType = get(format, "formatType");
  const customFormat =
    formatType === "custom" ? get(format, "customFormat") : null;

  if (customFormat) {
    return customFormat;
  }

  const outputTime = get(format, "outputTime");
  const inputTime = get(format, "inputTime");
  const inputDate = get(format, "inputDate");
  const showAmPm = get(format, "showAmPm");

  let formatString = "";

  if (inputDate && inputTime && outputTime !== "none") {
    formatString = `${inputDate} ${inputTime}`;
  } else if (inputDate) {
    formatString = inputDate;
  } else if (inputTime && outputTime !== "none") {
    formatString = inputTime;
  }

  if (!showAmPm) {
    // If not showing AM/PM, convert to 24 hour format
    formatString = formatString.replace("hh", "HH").replace("h", "HH");
  }

  return formatString;
};

export const defaultDateFormat = "YYYY/MM/DD";

export const formatEuro = (number, decimalPlaces = 0) => {
  const decimalsInt = parseInt(decimalPlaces, 0) || 0;

  // Create a new instance of Intl.NumberFormat for the 'de-DE' locale
  const formatter = new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: decimalsInt,
    maximumFractionDigits: decimalsInt,
  });

  // Format the number using the formatter
  return formatter.format(number);
};

export const euroToFloat = (euroString) => {
  // Remove all non-numeric characters except comma and period
  let onlyNumbersCommaAndPeriod = safeString(euroString).replace(
    /[^0-9,\.]/g,
    ""
  );

  // Remove periods used as thousands separators (if any remain after the above step, they are likely thousand separators)
  let withoutThousands = onlyNumbersCommaAndPeriod.replace(/\./g, "");

  // Replace comma with period to use as decimal separator
  let normalized = withoutThousands.replace(",", ".");

  if (normalized) {
    // Check if cleaned value contains a float
    if (/^-?\d+\.\d+$/.test(normalized)) {
      return parseFloat(normalized);
    }

    // Check if cleaned value contains an integer
    if (/^-?\d+$/.test(normalized)) {
      return parseInt(normalized, 10);
    }

    // If value contains numbers mixed with letters or is invalid, return 0
    return 0;
  }
  return 0;
};

export const parseNumber = (value) => {
  if (value) {
    // Remove commas, spaces, and any currency symbols
    const cleanedValue = value.toString().replace(/[,€£$¥₹%\s]/g, "");

    // Check if cleaned value contains a float
    if (/^-?\d+\.\d+$/.test(cleanedValue)) {
      return parseFloat(cleanedValue);
    }

    // Check if cleaned value contains an integer
    if (/^-?\d+$/.test(cleanedValue)) {
      return parseInt(cleanedValue, 10);
    }

    // If value contains numbers mixed with letters or is invalid, return 0
    return 0;
  }
  return 0;
};

export const getBlockIcon = (t) =>
  get(
    allBlocks.find((bl) => bl.type === t),
    "icon"
  );

export const lowerArray = (arr) => {
  return arr.map((item) => safeLower(item));
};

export const safeLower = (str) => {
  if (typeof str === "string") {
    return str.toLowerCase();
  }

  if (isNil(str)) {
    return "";
  }

  return str.toString();
};

export function safeParseFloatOrInt(str) {
  const parsedFloat = parseFloat(str);
  const parsedInt = parseInt(str, 10);

  // Check if the parsedFloat is a valid number (not NaN) and the same as the parsedInt
  if (!isNaN(parsedFloat) && parsedFloat === parsedInt) {
    return parsedInt; // Return the parsed integer if it's a valid integer
  } else if (!isNaN(parsedFloat)) {
    return parsedFloat; // Return the parsed float if it's a valid float
  } else {
    return 0; // Return 0 if neither a valid float nor integer
  }
}

const getArrayFromCommaSeparatedString = (s) => {
  const split = s
    ? s
        .toString()
        .split(",")
        .map((v) => v.trim())
        .filter((s) => s !== "")
    : [];

  return split;
};

export const migrateConditionFormat = (d) => {
  const {
    id,
    key,
    value,
    dataSource,
    block,
    value1,
    value2,
    operator = "contains",
    conditionType = "and",
  } = d;

  if (value1 || value2) {
    // NEW FORMAT
    return d;
  } else if (!key && !value && !dataSource && !block) {
    // EMPTY
    return d;
  } else {
    // OLD FORMAT - CONVERT TO NEW
    let parts = [dataSource];

    if (block) {
      parts.push(block);
    }

    if (key) {
      parts.push(key);
    }

    const value1 = `{{ ${parts.join(".")} }}`;

    return { id, value1, operator, value2: value, conditionType };
  }
};

// TODO - Figure out how to ensure the app date format is passed in.
// Right now it could still work as long as the format passed in already has the app format in it
export const passesCondition = (data) => {
  const {
    value1 = "",
    value2 = "",
    operator = "contains",
    value1DateFormat = null,
    // value2DateFormat = null,
  } = data;

  let meetsCondition = false;

  // EXISTS
  if (operator === "exists") {
    meetsCondition = !isNil(value1) && value1 !== "";
  }

  // DOES NOT EXIST
  else if (operator === "does_not_exist") {
    meetsCondition = isNil(value1) || value1 === "";
  }

  // IS TRUE
  else if (operator === "is_true") {
    meetsCondition = parseBoolean(value1);
  }

  // IS FALSE
  else if (operator === "is_false") {
    meetsCondition = !parseBoolean(value1);
  }

  // EQUALS
  else if (operator === "equals") {
    const value1Array = lowerArray(getArrayFromCommaSeparatedString(value1));
    const value2Array = lowerArray(getArrayFromCommaSeparatedString(value2));

    // COMPARE 2 COMMA-SEPARATED LISTS
    let matches = 0;
    value1Array.forEach((v1) => {
      value2Array.forEach((v2) => {
        if (safeLower(v1) === safeLower(v2)) {
          matches += 1;
        }
      });
    });

    meetsCondition = matches > 0;
  }

  // DOES NOT EQUAL
  else if (operator === "does_not_equal") {
    meetsCondition = safeLower(value1) !== safeLower(value2);
  }

  // STRING EQUALS STRING
  else if (operator === "string_equals_string") {
    meetsCondition = safeLower(value1) === safeLower(value2);
  }

  // IN
  else if (operator === "in" || operator === "string_in_array") {
    const value2Array = lowerArray(getArrayFromCommaSeparatedString(value2));

    if (value2Array.length === 0) {
      return false;
    }

    meetsCondition = value2Array.includes(safeLower(value1));
  }

  // NOT IN
  else if (operator === "string_not_in_array") {
    const value2Array = getArrayFromCommaSeparatedString(value2);

    if (value2Array.length === 0) {
      return true;
    }

    meetsCondition = !value2Array.includes(safeLower(value1));
  }

  // STRING IN STRING
  else if (operator === "string_in_string") {
    meetsCondition = safeLower(value2).includes(safeLower(value1));
  }

  // STRING NOT IN STRING (or defaulting for the generic not_in operator)
  else if (operator === "string_not_in_string" || operator === "not_in") {
    meetsCondition = !safeLower(value2).includes(safeLower(value1));
  }

  // CONTAINS - I reverted this change
  else if (operator === "contains" || operator === "array_contains_string") {
    if (value1.includes(",") || value2.includes(",")) {
      const value1Array = lowerArray(getArrayFromCommaSeparatedString(value1));
      const value2Array = lowerArray(getArrayFromCommaSeparatedString(value2));

      if (value1Array.length === 0 || value2Array.length === 0) {
        return false;
      }

      // Check if any element in value2Array exists in value1Array
      meetsCondition = value2Array.some((val2) =>
        value1Array.includes(safeLower(val2))
      );
    } else {
      meetsCondition = safeLower(value1).includes(safeLower(value2));
    }
  }

  // STRING CONTAINS STRING
  else if (operator === "string_contains_string") {
    meetsCondition = safeLower(value1).includes(safeLower(value2));
  }

  // DOES NOT CONTAIN
  else if (
    operator === "does_not_contain" ||
    operator === "string_does_not_contain_string"
  ) {
    meetsCondition = !safeLower(value1).includes(safeLower(value2));
  }

  // ARRAY DOES NOT CONTAIN STRING
  else if (operator === "array_does_not_contain_string") {
    const value1Array = lowerArray(getArrayFromCommaSeparatedString(value1));
    const lowerValue2 = safeLower(value2);

    if (value1Array.length === 0 || !lowerValue2) {
      return true;
    }

    meetsCondition = !value1Array.includes(lowerValue2);
  }

  // LENGTH GREATER THAN
  else if (operator === "length_greater_than") {
    meetsCondition = safeLower(value1).length > Number(value2);
  }

  // LENGTH LESS THAN
  else if (operator === "length_less_than") {
    meetsCondition = safeLower(value1).length < Number(value2);
  }

  // GREATER THAN
  else if (operator === "greater_than") {
    meetsCondition = Number(value1) > Number(value2);
  }

  // LESS THAN
  else if (operator === "less_than") {
    meetsCondition = Number(value1) < Number(value2);
  }

  // DATE EQUALS
  else if (operator === "date_equals") {
    const date1 = parseDateWithFormatObject({
      value: value1,
      formatObject: value1DateFormat,
      returnMoment: true,
    });

    // Hard-coding the match the date format of the datepicker component
    const date2 = parseDateWithFormatObject({
      value: value2,
      formatObject: value1DateFormat,
      returnMoment: true,
    });

    if (date1 && date2) {
      meetsCondition = date1.isSame(date2, "day");
    } else {
      meetsCondition = false;
    }
  }

  // DATE AFTER
  else if (operator === "date_after") {
    const date1 = parseDateWithFormatObject({
      value: value1,
      formatObject: value1DateFormat,
      returnMoment: true,
    });
    const date2 = parseDateWithFormatObject({
      value: value2,
      formatObject: value1DateFormat,
      returnMoment: true,
    });

    if (date1 && date2) {
      meetsCondition = date1.isAfter(date2);
    } else {
      meetsCondition = false;
    }
  }

  // DATE BEFORE
  else if (operator === "date_before") {
    const date1 = parseDateWithFormatObject({
      value: value1,
      formatObject: value1DateFormat,
      returnMoment: true,
    });
    const date2 = parseDateWithFormatObject({
      value: value2,
      formatObject: value1DateFormat,
      returnMoment: true,
    });

    if (date1 && date2) {
      meetsCondition = date1.isBefore(date2);
    } else {
      meetsCondition = false;
    }
  }

  // DATE RANGE
  else if (operator === "date_in_range") {
    const date1 = parseDateWithFormatObject({
      value: value1,
      formatObject: value1DateFormat,
      returnMoment: true,
    });
    const [start, end] = value2.split("|");

    if (start && end) {
      const rangeStart = parseDateWithFormatObject({
        value: start,
        formatObject: value1DateFormat,
        returnMoment: true,
      });

      const rangeEnd = parseDateWithFormatObject({
        value: end,
        formatObject: value1DateFormat,
        returnMoment: true,
      });

      if (date1 && rangeStart && rangeEnd) {
        meetsCondition = date1.isBetween(rangeStart, rangeEnd, "day", "[]");
      } else {
        meetsCondition = true;
      }
    }
  }

  // NUMBER IN RANGE
  else if (operator === "number_in_range") {
    const [min, max] = value2.split("|");

    if (!min && !max) {
      return true;
    } else if (min && max) {
      meetsCondition =
        Number(value1) >= Number(min) && Number(value1) <= Number(max);
    } else if (min) {
      meetsCondition = Number(value1) >= Number(min);
    } else if (max) {
      meetsCondition = Number(value1) <= Number(max);
    }
  }

  return meetsCondition;
};

export const dateFormatOptions = [
  {
    label: "YYYY/MM/DD",
    value: "YYYY/MM/DD",
  },
  {
    label: "YYYY/DD/MM",
    value: "YYYY/DD/MM",
  },
  {
    label: "DD/MM/YYYY",
    value: "DD/MM/YYYY",
  },
  {
    label: "MM/DD/YYYY",
    value: "MM/DD/YYYY",
  },
  {
    label: "YYYY-MM-DD",
    value: "YYYY-MM-DD",
  },
  {
    label: "YYYY-DD-MM",
    value: "YYYY-DD-MM",
  },
  {
    label: "DD-MM-YYYY",
    value: "DD-MM-YYYY",
  },
  {
    label: "MM-DD-YYYY",
    value: "MM-DD-YYYY",
  },
];

function detectDateFormat(input) {
  let formats = [
    "DD-MM-YYYY",
    "D-MM-YYYY",
    "DD-M-YYYY",
    "D-M-YYYY",
    "MM-DD-YYYY",
    "M-DD-YYYY",
    "MM-D-YYYY",
    "M-D-YYYY",
    "YYYY-MM-DD",
    "YYYY-M-DD",
    "YYYY-MM-D",
    "YYYY-M-D",
    "DD/MM/YYYY",
    "D/MM/YYYY",
    "DD/M/YYYY",
    "D/M/YYYY",
    "MM/DD/YYYY",
    "M/DD/YYYY",
    "MM/D/YYYY",
    "M/D/YYYY",
    "YYYY/MM/DD",
    "YYYY/M/DD",
    "YYYY/MM/D",
    "YYYY/M/D",
    "DD-MM-YY",
    "D-M-YY",
    "MM-DD-YY",
    "M-D-YY",
    "YY-MM-DD",
    "YY-M-D",
    "DD/MM/YY",
    "D/M/YY",
    "MM/DD/YY",
    "M/D/YY",
    "YY/MM/DD",
    "YY/M/D",
    "DD.MM.YYYY",
    "D.MM.YYYY",
    "DD.M.YYYY",
    "D.M.YYYY",
    "MM.DD.YYYY",
    "M.DD.YYYY",
    "MM.D.YYYY",
    "M.D.YYYY",
    "YYYY.MM.DD",
    "YYYY.M.DD",
    "YYYY.MM.D",
    "YYYY.M.D",
    "DD.MM.YY",
    "D.M.YY",
  ];
  for (let i = 0; i < formats.length; i++) {
    if (moment(input, formats[i], true).isValid()) {
      return formats[i];
    }
  }
  return "";
}

export const getHighest = (source, field = "id") => {
  let highestId = 0;

  if (!source) {
    return highestId;
  }

  safeArray(source).forEach((f) => {
    const match = get(f, field) && parseInt(get(f, field));
    if (match && match > highestId) {
      highestId = match;
    }
  });
  return highestId;
};

export const getWidth = (width, widthUnit) => {
  if (widthUnit === "auto" || !parseInt(width)) {
    return "auto";
  }
  return `${width}${widthUnit}`;
};

export const getDuplicates = (allItems, key) => {
  let items = [];
  let dupes = [];
  allItems.forEach((i) => {
    const m = get(i, key);

    if (m !== "") {
      if (!items.includes(m)) {
        items.push(m);
      } else {
        if (!dupes.includes(m)) {
          let isNumeric = false;

          try {
            if (parseFloat(m)) {
              isNumeric = true;
            }
            if (parseInt(m)) {
              isNumeric = true;
            }
          } catch (e) {
            // DO NOTHING
          }

          // If not numeric, add to duplicates
          if (!isNumeric) {
            dupes.push(m);
          }
        }
      }
    }
  });

  return { items, dupes };
};

export const getUniqueValues = (array, key, componentId = null) => {
  if (!isArray(array)) {
    return [];
  }

  if (componentId === "MultiSelect") {
    // Split each array item (a comma-separated string) into a new flat list
    const finalArray = array
      .reduce((result, item) => {
        const beforeSplit = safeString(get(item, key, "")).toString();
        const splitItem = beforeSplit.split(",");
        return result.concat(splitItem);
      }, [])
      .map((v) => v.trim());

    const uniqueVals = finalArray.reduce((result, item) => {
      if (result.indexOf(item) === -1) {
        result.push(item);
      }
      return result;
    }, []);
    return uniqueVals;
  }

  let uniqueValues = array.reduce((result, item) => {
    if (result.indexOf(item[key]) === -1) {
      result.push(get(item, key));
    }
    return result;
  }, []);

  return uniqueValues;
};

export const getConditionBasedUserGroups = (
  appUserGroups,
  passesDisplayConditions
) => {
  const automaticGroups = safeArray(appUserGroups)
    .filter((b) => {
      const conditions = get(b, "conditions", []);

      // In this case, if there are no conditions we need to consider it FALSE
      // Only if a condition exists and is met, we add the group
      if (conditions.length === 0) {
        return false;
      }

      // if conditions exist, then check if they are met
      return passesDisplayConditions({ conditions });
    })
    .map((m) => m.id);

  return automaticGroups;
};

export const filterBasedOnUserGroups = (data) => {
  const { items, app, user, passesDisplayConditions } = data;

  const activeUserGroups = get(user, ["user_groups", app.id], []);

  const appUserGroups = get(app, "user_groups", []) || [];

  let currentUserGroups = [...activeUserGroups];

  // If passesDisplayConditions passed in, use it
  if (passesDisplayConditions) {
    const automaticGroups = getConditionBasedUserGroups(
      appUserGroups,
      passesDisplayConditions
    );
    currentUserGroups = uniq([...activeUserGroups, ...automaticGroups]);
  }

  // If no user groups set for a user, we consider them able to access everything
  if (user && currentUserGroups.length === 0) {
    return items;
  }

  const appUserGroupIds = appUserGroups.map((g) => g.id);

  if (!isFrontlyAdmin) {
    // Loop through each item to check if it matches the user group requirements
    return items.filter((p) => {
      const itemGroups = get(p, "userGroups", []);

      // Filter out any non-existant groups before the comparison
      const liveGroups = itemGroups.filter((g) => appUserGroupIds.includes(g));

      if (!liveGroups || liveGroups.length === 0) {
        // No live groups, make visible
        return true;
      }

      // Loop through the active user's user groups, and check if any of them match the requirements for this page
      let matchesGroup = false;

      liveGroups.forEach((g) => {
        if (currentUserGroups && currentUserGroups.includes(g)) {
          matchesGroup = true;
        }
      });

      return matchesGroup;
    });
  }

  // Return all pages, is admin
  return items;
};

export const getRelatedSheets = (sheetId, activeApp, spreadsheets) => {
  const spreadsheetIds = spreadsheets.map((s) => s.id);

  const relations = (get(activeApp, "data_relations", []) || []).filter(
    (r) =>
      r.sheet1 &&
      r.sheet2 &&
      spreadsheetIds.includes(r.sheet1) &&
      spreadsheetIds.includes(r.sheet2)
  );

  // Get all related headers
  return relations
    .filter((r) => sheetId === r.sheet2)
    .map((r) => {
      const relatedSheet = spreadsheets.find((s) => s.id === r.sheet1);
      const relatedSheetHeaders = get(relatedSheet, "headers", []);

      const relationId = get(r, "relationId") || get(r, "column2");

      const relationColumns = { other: r.column1, current: r.column2 };

      const headers = relatedSheetHeaders.map((h) => `${relationId}__${h}`);

      return {
        id: r.id,
        relatedSheet,
        headers,
        display_column: r.display_column,
        relationKey: relationId,
        relationColumns,
      };
    });
};

export const allowedInsideCustomReusable = [
  "Text",
  "RichText",
  "Image",
  "Button",
  "Row",
  "Column",
  "Icon",
  "Video",
];

export const getGridStaticItems = (count, size = "300px") => {
  if (count === 1) {
    return size;
  } else if (count === 2) {
    return `${size} ${size}`;
  } else if (count === 3) {
    return `${size} ${size} ${size}`;
  }
};

// Ensure that blocks with layoutParent have parent set to null
export const fixCustomBlocks = (blocks) => {
  const blockIds = blocks.map((b) => b.id);

  return blocks.map((b) => {
    let block = { ...b };
    if (b.layoutParent && !blockIds.includes(b.layoutParent)) {
      delete block.layoutParent;
    }
    if (b.parent && !blockIds.includes(b.parent)) {
      delete block.parent;
    }

    return block;
  });
};

// Ensure that blocks with layoutParent have parent set to null
export const fixBlocks = (blocks) => {
  return blocks.map((b) => {
    let block = { ...b, parentComponentId: null };
    if (b.layoutParent) {
      delete block.parent;
    }
    return block;
  });
};

export const getLayoutSizes = (arr) => {
  let sizes = ["desktop"];

  const tablet = arr.find((b) => get(b, ["gridPosition", "tablet"]));
  if (tablet) {
    sizes.push("tablet");
  }

  const mobile = arr.find((b) => get(b, ["gridPosition", "mobile"]));
  if (mobile) {
    sizes.push("mobile");
  }

  return sizes;
};

export const getGoogleSheetsEndpoint = () => {
  const token = Cookies.get("accessToken");
  if (token) {
    return "/google_sheets/";
  }
  return "/google_sheets_anonymous/";
};

export const isObjectEqualFromKeys = (obj1, obj2, keys) => {
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];

    // console.log("key", key);
    // console.log("get(obj1, key)", get(obj1, key));
    // console.log("get(obj2, key)", get(obj2, key));

    if (!isEqual(get(obj1, key), get(obj2, key))) {
      return false;
    }
  }
  return true;
};

export const getCurrentDomain = () => {
  let url = window.location.href;

  if (url.includes("localhost")) {
    url = "http://app.frontly.ai";
  }

  const splitURL = url
    .replace("https://", "")
    .replace("http://", "")
    .split("/");

  const domain = splitURL[0].toLowerCase();

  return domain;
};

export const isValidSubdomain = (subdomain) => {
  const errors = {};

  if (!subdomain) {
    errors["subdomain"] = "Please enter a valid subdomain.";
  } else if (subdomain.length < 3) {
    errors["subdomain"] = "Please enter a longer subdomain.";
  } else if (subdomain.toLowerCase().includes("frontly")) {
    errors["subdomain"] =
      "'Frontly' is a protected word and cannot be used in a subdomain.";
  } else if (!/^[a-zA-Z0-9._-]*$/.test(subdomain)) {
    // only hyphens
    errors["subdomain"] =
      "Spaces and special characters are not allowed in subdomain.";
  }

  return errors;
};

export const findDefaultPage = (data) => {
  const { user, userGroups, pages, defaultPage, app, passesDisplayConditions } =
    data;

  let route = null;

  const appId = get(app, "id");

  let activeUserGroupIds = get(user, ["user_groups", appId], []);
  const appUserGroups = get(app, "user_groups", []) || [];

  // If passesDisplayConditions passed in, use it
  if (passesDisplayConditions) {
    const automaticGroups = getConditionBasedUserGroups(
      appUserGroups,
      passesDisplayConditions
    );
    activeUserGroupIds = uniq([...activeUserGroupIds, ...automaticGroups]);
  }

  if (activeUserGroupIds.length > 0) {
    // REDIRECT TO DEFAULT PAGE FOR THIS USER GROUP
    const match = userGroups.find((ug) => activeUserGroupIds.includes(ug.id));

    if (match) {
      const userGroupDefaultPage = pages.find(
        (p) => p.id === get(match, "defaultPage")
      );
      if (userGroupDefaultPage) {
        route = addSlash(get(userGroupDefaultPage, "route"));
      } else {
        route = addSlash(get(defaultPage, "route"));
      }
    } else {
      route = addSlash(get(defaultPage, "route"));
    }
  } else {
    route = addSlash(get(defaultPage, "route"));
  }

  return route;
};

export const openPageInNewTab = ({ page, app, token = "", routeId = "" }) => {
  const subdomain = get(app, "subdomain");

  const customDomain = get(app, ["custom_domain", "custom_domain"]);

  const pageRoute = get(page, "route");

  let previewPageLink = "";

  previewPageLink = `https://${subdomain}.frontly.ai`;

  if (customDomain) {
    previewPageLink = `https://${customDomain}`;
  }

  previewPageLink += `/${pageRoute}/${routeId}`;

  if (token) {
    previewPageLink += `?magic=${token}`;
  }

  if (previewPageLink.includes("/?magic=")) {
    previewPageLink = previewPageLink.replace("/?magic=", "?magic=");
  }

  window.open(previewPageLink);
};

export const isFrontlyAdmin = [
  "app.frontly.ai",
  "www.frontly.ai",
  "frontly-ai.herokuapp.com",
  "frontly-ai-staging-4b7d4a4d7c04.herokuapp.com",
].includes(getCurrentDomain());

export const arrayMove = (arrayItems, removedIndex, addedIndex) => {
  const arrCopy = cloneDeep(arrayItems);
  const removedItem = arrCopy.splice(removedIndex, 1);
  arrCopy.splice(addedIndex, 0, removedItem[0]);

  return arrCopy;
};

export const addSlash = (route) => {
  if (!route) {
    return null;
  }
  if (startsWith(route, "/") || route.includes("http")) {
    return route;
  }
  return `/${route}`;
};

export const downloadAsCsv = (rows, headers = [], fileName = "data") => {
  const csvRows = [
    headers
      .map((h) => (h === "frontly_id" ? "fid" : h))
      .map((h) => `"${h.replace(/"/g, '""')}"`)
      .join(","),
    ...rows.map((row) =>
      headers
        .map((h) => {
          const rowV = get(row, h, "") || "";
          return `"${rowV.toString().replace(/"/g, '""')}"`;
        })
        .join(",")
    ),
  ];

  const csvString = csvRows.join("\n");

  const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
    csvString
  )}`;

  const link = document.createElement("a");
  link.setAttribute("href", csvContent);
  link.setAttribute("download", `${fileName.replace(".csv", "")}.csv`);
  document.body.appendChild(link); // Required for FF (Firefox)
  link.click();
};

// export const downloadComponentAsPDF = async (componentRef) => {
//   // Capture the component
//   const canvas = await html2canvas(componentRef.current);
//   const screenshotDataURL = canvas.toDataURL("image/png");

//   // Create a new jsPDF instance and calculate image dimensions
//   const pdf = new jsPDF("p", "mm", "a4");
//   const imgProps = pdf.getImageProperties(screenshotDataURL);
//   const pdfWidth = pdf.internal.pageSize.getWidth();
//   const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

//   // Add the image to the PDF and adjust dimensions to maintain aspect ratio
//   pdf.addImage(screenshotDataURL, "PNG", 0, 0, pdfWidth, pdfHeight);

//   // Trigger download
//   pdf.save("component-screenshot.pdf");
// };

export const parseIntFromPixels = (number) => {
  const num = safeString(number);

  if (num.endsWith("px")) {
    return parseInt(num.replace("px", ""));
  }
  return parseInt(num);
};

export const getPixels = (number) => {
  if (number === 0 || number === "0") {
    return "0px";
  }
  // VIEWHEIGHT STRING
  if (isString(number) && number.endsWith("vh")) {
    return number;
  }
  // PERCENT STRING
  if (isString(number) && number.endsWith("%")) {
    return number;
  }
  // PIXEL STRING
  if (isString(number) && number.includes("px")) {
    return number;
  }
  // INTEGER
  if (parseInt(number)) {
    return `${parseInt(number)}px`;
  }
  return number;
};

export const truncateText = (text, length) => {
  if (typeof text === "object") {
    return "Object";
  }

  if (!isNaN(text)) {
    text = text && text.toString();
  }
  if (!text) {
    return "";
  }
  if (text.length > length) {
    return text.substring(0, length) + "...";
  } else {
    return text;
  }
};

export function validURL(str) {
  return str.includes("http");
}

export const getValueType = (value) => {
  let valueType = typeof value;

  if (Array.isArray(value)) {
    valueType = "array";
  } else if (value === "") {
    valueType = "empty string";
  } else if (value === null) {
    valueType = "null";
  }
  return valueType;
};

export const resizeImage = ({
  url,
  height,
  quality = null,
  forceJpg = false,
  extraMods = null,
}) => {
  if (!url) {
    return null;
  }

  url = url.toString();

  if (!url.includes("cloudinary")) {
    return url;
  }

  // Don't resize PDF
  if (url.includes(".pdf")) {
    return url;
  }

  const isResized = url.includes(`/h_`);

  let finalUrl = url;

  let mods = `h_${height}/`;

  if (extraMods) {
    mods = `${extraMods},${mods}/`;
  }

  if (quality) {
    mods = `${mods}q_${quality}/`;
  }

  if (forceJpg) {
    mods = `${mods}f_jpg/`;
  }

  if (isResized) {
    // ALREADY RESIZED
    const splitUrl = url.split("upload/");
    const existingMod = splitUrl[1].split("/")[0];
    finalUrl = url.replace(existingMod, mods);
  } else {
    // NOT RESIZED
    const splitUrl = url.split("upload/");
    const newUrl = `${splitUrl[0]}upload/${mods}${splitUrl[1]}`;
    finalUrl = newUrl;
  }

  return finalUrl;
};

export const parseBoolean = (value) => {
  if (typeof value === "boolean") return value;

  if (typeof value === "string") {
    const trimmedValue = value.trim().toLowerCase();

    switch (trimmedValue) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return false;
    }
  }

  return false;
};

export const getUrlParameter = (name, location) => {
  if (!name) {
    return null;
  }

  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  const regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  const results = regex.exec(location.search);
  let value =
    results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));

  if (value === "true") {
    value = true;
  } else if (value === "false") {
    value = false;
  } else if (isInteger(value)) {
    return parseInt(value);
  }

  return value;
};

export const handleClientSidePagination = ({
  items,
  currentPage,
  itemsPerPage,
}) => {
  let firstItem = itemsPerPage * currentPage - itemsPerPage;
  let lastItem = itemsPerPage * currentPage;

  return items.filter((i, index) => index >= firstItem && index < lastItem);
};

export const goToRoute = (route, externalLink, navigate) => {
  if (route.includes("EXTERNAL_LINK") && validURL(externalLink)) {
    window.open(externalLink);
  } else if (validURL(route)) {
    window.open(route);
  } else {
    navigate(addSlash(route));
  }
};

export const addTransparency = (hex = "") => {
  if (!hex) {
    return hex;
  }

  const { length } = hex;
  const hasTransparency = length === 9;
  if (hasTransparency) {
    return hex;
  } else {
    return hex + "ff";
  }
};

export const upToFiveItems = (items = []) => {
  if (items.length > 5) {
    return items.slice(1);
  }
  return items;
};
