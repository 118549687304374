import styled from "styled-components";

const Row = styled.div`
  display: flex;
  align-items: ${(p) => p.alignItems || "flex-start"};
  justify-content: ${(p) => p.justifyContent || "flex-start"};
  width: ${(p) => p.width || "auto"};
  gap: ${(p) => p.gap || "0px"};
  margin: ${(p) => p.margin || "0px"};
`;

export default Row;
