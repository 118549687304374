import { fetchIfHidden, label, labelColor, spreadsheetSelect } from "./shared";

import { get } from "lodash";
import { safeArray } from "app/utils/utils";

const meetsModeRowIdCondition = (block) => {
  if (block.mode === "edit") {
    return block.rowId;
  }
  return true;
};

export const Form = {
  componentId: "Form",
  resources: [
    label,
    labelColor,
    {
      id: "hideHeader",
      componentId: "Switch",
      section: "hideHeader",
      orientation: "horizontal",
      requiresSheet: true,
      advanced: true,
      sectionHint:
        "Hide the block header entirely so it doesn't take up any space",
    },
    {
      ...spreadsheetSelect,
      hint: "Select the spreadsheet to create new rows in with this Form",
    },
    {
      id: "useDefaultsInEditMode",
      label: "Use Default Values In Edit Mode",
      hint: "Apply the default values to the form in edit mode. By default, default values will only be applied in create mode.",
      componentId: "Switch",
      defaultValue: false,
      section: "content",
      advanced: true,
      displayCondition: (block) =>
        meetsModeRowIdCondition(block) && block.mode === "edit",
    },
    {
      id: "gridLayout",
      sectionHint:
        "Display the form fields in a responsive grid instead of vertically stacking",
      componentId: "Switch",
      defaultValue: false,
      section: "gridLayout",
      isStyle: true,
      displayCondition: (block) => meetsModeRowIdCondition(block),
    },
    {
      id: "fieldData",
      label: "Fields",
      componentId: "StaticFields",
      section: "formFields",
      requiresSheet: true,
      displayCondition: (block) => meetsModeRowIdCondition(block),
      keys: [
        {
          id: "label",
          label: "Label",
          hint: "Override the default label",
          componentId: "Input",
          required: true,
          defaultValue: "",
        },
        {
          id: "checkboxDescription",
          label: "Checkbox Text",
          hint: "Description to display for your checkbox",
          componentId: "Input",
          required: true,
          defaultValue: "",
          displayCondition: (f) => get(f, "componentId") === "Checkbox",
        },
        {
          id: "componentId",
          label: "Display Component",
          hint: "Determine the visual component used to display this field",
          componentId: "Select",
          required: true,
          defaultValue: "Input",
          hideEmptyItem: true,
          options: [
            { label: "Input", value: "Input" },
            { label: "Select", value: "Select" },
            { label: "MultiSelect", value: "MultiSelect" },
            { label: "Switch", value: "Switch" },
            { label: "Checkbox", value: "Checkbox" },
            { label: "Text Area", value: "TextArea" },
            { label: "Date", value: "DateTimePicker" },
            // { label: "Time", value: "TimePicker" },
            { label: "Image", value: "ImageUpload" },
            { label: "File Upload", value: "FileUpload" },
            // { label: "Document Upload", value: "DocumentUpload" },
            { label: "Markdown", value: "MarkdownEditor" },
            { label: "Hidden", value: "Hidden" },
            { label: "Signature", value: "Signature" },
          ],
        },
        {
          id: "hiddenValue",
          label: "Hidden Value",
          componentId: "DynamicString",
          displayCondition: (f) => get(f, "componentId") === "Hidden",
        },
        {
          id: "description",
          label: "Description",
          hint: "Display a description for this field",
          componentId: "Input",
          required: false,
          defaultValue: "",
          advanced: true,
        },
        {
          id: "hint",
          label: "Hint",
          hint: "Display a hint for this field",
          componentId: "Input",
          required: false,
          defaultValue: "",
          advanced: true,
        },
        {
          id: "placeholder",
          label: "Placeholder",
          hint: "Display a placeholder for this field",
          componentId: "Input",
          required: false,
          defaultValue: "",
          advanced: true,
        },
        {
          id: "defaultValue",
          label: "Default Value",
          hint: "Provide a default value for this field",
          componentId: "DynamicString",
          required: false,
          advanced: true,
        },
        {
          id: "selectSorting",
          label: "Sort Options",
          componentId: "Select",
          requiresSheet: true,
          hideEmptyItem: true,
          options: [
            { label: "None", value: null },
            { label: "Ascending", value: "asc" },
            { label: "Descending", value: "desc" },
          ],
          advanced: true,
          displayCondition: (field) =>
            ["Select", "MultiSelect"].includes(get(field, "componentId")),
        },
        {
          id: "selectText",
          label: "Select Text",
          componentId: "Input",
          orientation: "vertical",
          hint: "Override the default 'Select' text for when no value is selected",
          requiresSheet: true,
          orientation: "vertical",
          displayCondition: (field) =>
            ["Select", "MultiSelect"].includes(get(field, "componentId")),
        },
        {
          id: "options",
          label: "Options",
          componentId: "DataGrid",
          orientation: "vertical",
          hint: "Define the options to appear in your select dropdown. Auto detected any unique values in the sheet. Adjust them as you see fit.",
          requiresSheet: true,
          newObject: { label: "New Item", value: "New Value" },
          orientation: "vertical",
          columns: [
            {
              key: "label",
              componentId: "Input",
            },
            {
              key: "value",
              componentId: "Input",
            },
          ],
          displayCondition: (field) =>
            ["Select", "MultiSelect"].includes(get(field, "componentId")),
        },
        {
          id: "validation",
          label: "Validation",
          componentId: "FormValidation",
        },
        {
          id: "conditions",
          label: "Display Conditions",
          componentId: "DisplayConditions",
          orientation: "vertical",
          allowSpreadsheet: true,
          advanced: true,
        },
        {
          id: "columnSpan",
          label: "Column Span",
          hint: "Force this field to take up more than 1 field's width in the form. Usful for large fields like text areas that need more space.",
          componentId: "Select",
          options: [
            {
              label: "1 Column",
              value: 1,
            },
            {
              label: "2 Columns",
              value: 2,
            },
            {
              label: "3 Columns",
              value: 3,
            },
          ],
          required: false,
          defaultValue: 1,
          advanced: true,
        },
        {
          id: "minHeight",
          label: "Min Height",
          hint: "Set a minimum default height for the TextArea field.",
          componentId: "Select",
          options: [
            {
              label: "100px",
              value: "100px",
            },
            {
              label: "150px",
              value: "150px",
            },
            {
              label: "200px",
              value: "200px",
            },
            {
              label: "300px",
              value: "300px",
            },
          ],
          displayCondition: (f) => get(f, "componentId") === "TextArea",
          required: false,
        },
        {
          id: "disabled",
          label: "Disabled",
          hint: "Prevent the field from being edited",
          componentId: "Switch",
          required: true,
          defaultValue: false,
        },
        // Show hidden filters here if select dropdown AND has a relation
        {
          id: "hiddenFilters",
          label: "Hidden Filters",
          componentId: "HiddenFiltersConfig",
          showOnlyRelationFields: true,
          requiresSheet: true,
          advanced: true,
          orientation: "vertical",
          hint: "Filter the initial data that users can access",
          link: "https://help.frontly.ai/en/articles/7971258-hidden-filters",
          displayCondition: (fieldData, fieldId, customObj) => {
            // This is pretty hacky, but since the Form config is just an object,
            // We need to pass in this data to be able to conditionally show hiddenFilters

            const sheetId = get(customObj, "sheetId");
            const activeApp = get(customObj, "app", {});

            const relationFieldKeys = safeArray(activeApp, "data_relations")
              .filter((r) => r.sheet2 === sheetId)
              .map((r) => r.column2);

            return relationFieldKeys.includes(fieldId);
          },
        },
      ],
    },
    {
      id: "mode",
      componentId: "Select",
      section: "formMode",
      orientation: "vertical",
      sectionHint:
        "Whether to Create new Google Sheet rows or Edit existing rows.",
      requiresSheet: true,
      hideEmptyItem: true,
      wizard: true,
      options: [
        {
          label: "Edit",
          value: "edit",
        },
        {
          label: "Create",
          value: "create",
        },
      ],
    },
    {
      id: "rowId",
      label: "Row ID",
      hint: "The frontly_id of the row you're editing. Most of the time this is a dynamic variable.",
      componentId: "DynamicString",
      section: "setup",
      orientation: "horizontal",
      width: "150px",
      wizard: true,
      requiresSheet: true,
      displayCondition: (block) => block.mode === "edit",
    },
    {
      id: "rowIdColumn",
      label: "Row Column",
      section: "setup",
      orientation: "horizontal",
      width: "150px",
      componentId: "SpreadsheetColumnSelect",
      requiresSheet: true,
      defaultValue: "frontly_id",
      hint: "The column to use in the row finding condition. Defaults to frontly_id.",
      displayCondition: (block) => block.mode === "edit",
    },
    {
      id: "submitText",
      label: "Submit Button Text",
      componentId: "Input",
      section: "content",
      requiresSheet: true,
      advanced: true,
      displayCondition: (block) => meetsModeRowIdCondition(block),
    },
    {
      label: "Submit Action",
      id: "submitAction",
      section: "actions",
      dynamicSources: ["form"],
      hint: "This action runs in addition to the default create or update action",
      isAction: true,
      componentId: "Action",
      requiresSheet: true,
      advanced: true,
      displayCondition: (block) => meetsModeRowIdCondition(block),
    },
    {
      id: "disableDefaultAction",
      label: "Disable Default Submit Action",
      hint: "By default, the Form automatically edits or creates a new record in the connected sheet. Disable this if you want to handle the action yourself with custom actions.",
      componentId: "Switch",
      defaultValue: false,
      advanced: true,
      section: "actions",
      displayCondition: (block) => meetsModeRowIdCondition(block),
    },
    {
      id: "hideSubmitButton",
      label: "Hide Submit Button",
      hint: "Hide the submit button and use custom actions to submit the form, triggered from somewhere outside the form",
      componentId: "Switch",
      defaultValue: false,
      advanced: true,
      section: "actions",
    },
    {
      id: "clearOnSubmit",
      label: "Clear Form Values On Submit",
      componentId: "Switch",
      defaultValue: false,
      advanced: true,
      section: "actions",
      displayCondition: (block) => block.mode === "create",
    },
    fetchIfHidden,
  ],
};
