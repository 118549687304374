import { inputBlockResources } from "./shared";

export const Input = {
  componentId: "Input",
  resources: [
    ...inputBlockResources,
    {
      label: "Text Align",
      id: "textAlign",
      section: "alignment",
      isStyle: true,
      componentId: "Select",
      orientation: "horizontal",
      width: "150px",
      hideEmptyItem: true,
      defaultValue: "left",
      options: [
        { label: "Left", value: "left" },
        { label: "Center", value: "center" },
        { label: "Right", value: "right" },
      ],
    },
    {
      id: "placeholder",
      label: "Placeholder",
      hint: "Display a placeholder for this field",
      componentId: "Input",
      required: false,
      defaultValue: "",
    },
    {
      id: "defaultValue",
      label: "Default Value",
      hint: "Enter a default value for this field",
      componentId: "Input",
      required: false,
      defaultValue: "",
    },
    {
      id: "disabled",
      label: "Disabled",
      componentId: "Switch",
      required: false,
      defaultValue: false,
    },
    {
      id: "validation",
      label: "Validation",
      componentId: "FormValidationSimple",
    },
  ],
};
