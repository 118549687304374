import {
  addFetchingBlocks,
  localStateSelector,
  rAllFormState,
  rApp,
  rClearConfig,
  rFetchingBlockIds,
  rFormIsFetching,
  rFormValidation,
  rForms,
  rLiveSpreadsheets,
  rLocalState,
  rModal,
  rPages,
  rSavedSpreadsheets,
  rSpinner,
  rToast,
  rUser,
  rWebsocketRequests,
  refreshBlockIdsSelector,
  spreadsheetsSelector,
  userStateSelector,
} from "app/utils/recoil";
import {
  addSlash,
  asyncActionTypes,
  downloadAsCsv,
  getCurrentDomain,
  getGoogleSheetsEndpoint,
  isFrontlyAdmin,
  isValidJson,
  safeArray,
  safeString,
} from "../utils/utils";
import { apiRequest, apiRequestWithCustomHeaders } from "app/utils/apiRequests";
import { errorNotification, successNotification } from "../utils/Notification";
import { get, isEmpty } from "lodash";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import useValidateFields, { getFieldValidation } from "app/utils/validation";

import axios from "axios";
import { migrateActionSteps } from "app/adminApp/components/Action/Action";
import { rFormState } from "app/utils/recoil";
import useModalStateData from "app/useModalStateData";
import { useNavigate } from "react-router-dom";
import useSpreadsheetRequests from "app/useSpreadsheetRequests";
import useUtils from "app/renderingApp/useUtils";
import Cookies from "js-cookie";

const useActionResolver = (page) => {
  const setSpreadsheets = useSetRecoilState(spreadsheetsSelector);
  const savedSpreadsheets = useRecoilValue(rSavedSpreadsheets);
  const spreadsheets = useRecoilValue(rLiveSpreadsheets);

  const { validateFields } = useValidateFields();

  const user = useRecoilValue(rUser);
  const activeApp = useRecoilValue(rApp);
  const pages = useRecoilValue(rPages);
  const forms = useRecoilValue(rForms);

  const setModal = useSetRecoilState(rModal);

  const clearConfig = useSetRecoilState(rClearConfig);

  const [formState, setFormState] = useRecoilState(rFormState);
  const [allFormState, setAllFormState] = useRecoilState(rAllFormState);
  const setFormValidation = useSetRecoilState(rFormValidation);

  const setSpinner = useSetRecoilState(rSpinner);

  const setLocalState = useSetRecoilState(localStateSelector);

  const clearLocalState = useResetRecoilState(rLocalState);

  const setUser = useSetRecoilState(userStateSelector);

  const { processDynamicText, passesDisplayConditions } = useUtils();

  const [websocketRequests, setWebsocketRequests] =
    useRecoilState(rWebsocketRequests);

  const setFormIsFetching = useSetRecoilState(rFormIsFetching);

  const addToFetchingBlocks = useSetRecoilState(addFetchingBlocks);

  const clearFetchingBlocks = useResetRecoilState(rFetchingBlockIds);

  const setRefreshBlockIds = useSetRecoilState(refreshBlockIdsSelector);

  const { setModalStack } = useModalStateData();

  const { createRecord, updateRecord, deleteRecord, getRecord } =
    useSpreadsheetRequests();

  const navigate = useNavigate();

  const actions = get(page, "actions", []);

  if (isFrontlyAdmin) {
    // DONT RUN ACTION IN SETUP MODE
    return { actionResolver: () => null };
  }

  const handleActionStep = async (
    step,
    context,
    blockId = null,
    instanceId = null,
    reusableBlockId = null
  ) => {
    const processText = (text) =>
      processDynamicText({ text, context, reusableBlockId });

    // CUSTOM VARIABLE
    if (step.type === "CUSTOM_VARIABLE") {
      const variables = get(step, "variables", []);

      const customVariablesToFetch = get(activeApp, "custom_variables", [])
        .filter((v) => {
          return variables.includes(parseInt(v.id));
        })
        .map((v) => ({
          ...v,
          filters: get(v, "conditions", []).map((c) => ({
            ...c,
            value: processText(get(c, "value")),
          })),
          id: v.spreadsheet,
          is_variable: true,
        }));

      const response = await apiRequest.post(getGoogleSheetsEndpoint(), {
        endpoint_type: "multi",
        variables_to_fetch: customVariablesToFetch,
        domain: getCurrentDomain(),
      });

      const resData = get(response, "data");
      const variablesResponse = get(resData, "custom_variables", {});
      const currentVariables = get(spreadsheets, "custom_variables", {});

      setSpreadsheets({
        ...spreadsheets,
        custom_variables: { ...currentVariables, ...variablesResponse },
      });

      return variablesResponse;
    }

    // GOOGLE SHEET REQUEST
    else if (step.type === "GOOGLE") {
      const formData = get(step, "formData", {});

      if (step.actionType === "get") {
        const record = await getRecord({
          sheetId: step.spreadsheet,
          recordId: processText(step.rowId),
          rowIdColumn: step.rowIdColumn,
        });
        return record;
      } else if (step.actionType === "delete") {
        // DELETE ROW

        const match = safeArray(get(spreadsheets, blockId, []));

        if (match) {
          // If the block uses the spreadsheet directly, update it automatically
          // If it's a form (non array type), it will be skipped
          setSpreadsheets({
            ...spreadsheets,
            [blockId]: match.filter(
              (item) =>
                parseInt(item.frontly_id) !== parseInt(processText(step.rowId))
            ),
          });
        }

        // EDIT ROW
        await deleteRecord({
          sheetId: step.spreadsheet,
          recordId: processText(step.rowId),
          rowIdColumn: step.rowIdColumn,
        });

        if (!step.disableSuccessNotification) {
          successNotification(
            processText(step.successNotificationText) || "Record Deleted"
          );
        }

        return;
      } else {
        // Get changed values
        let updateValues = [];
        Object.keys(formData).forEach((k) => {
          const v = processText(get(formData, k), context);

          // Filter out empty values?
          if (v) {
            updateValues.push({ key: k, value: v });
          }
        });

        let updateObj = {};
        updateValues.forEach((v) => {
          updateObj[v.key] = v.value;
        });

        if (step.actionType === "edit") {
          const match = safeArray(get(spreadsheets, blockId, []));

          if (match) {
            // If the block uses the spreadsheet directly, update it automatically
            // If it's a form (non array type), it will be skipped
            setSpreadsheets({
              ...spreadsheets,
              [blockId]: match.map((item) => {
                if (
                  parseInt(item.frontly_id) ===
                  parseInt(processText(step.rowId))
                ) {
                  return { ...item, ...updateObj };
                }
                return item;
              }),
            });
          }

          const currentRecord = get(context, "record", {});

          // EDIT ROW
          const updateResponse = await updateRecord({
            sheetId: step.spreadsheet,
            recordId: processText(step.rowId),
            rowIdColumn: step.rowIdColumn,
            values: updateValues,
            currentRecord,
          });

          if (!step.disableSuccessNotification) {
            successNotification(
              processText(step.successNotificationText) || "Record Updated"
            );
          }

          return updateResponse;
        } else {
          // CREATE NEW ROW
          const createResponse = await createRecord({
            sheetId: step.spreadsheet,
            values: updateValues,
          });

          if (!step.disableSuccessNotification) {
            successNotification(
              processText(step.successNotificationText) || "Record Created"
            );
          }

          return createResponse;
        }
      }
    }
    // REFRESH BLOCKS
    else if (step.type === "REFRESH_BLOCKS") {
      const blockIds = get(step, "blocks", "")
        .split(",")
        .map((bId) => parseInt(bId));
      setRefreshBlockIds(blockIds);
      return;
    } else if (step.type === "WEB_SCRAPER") {
      const url = processText(get(step, "url"));
      const response = await apiRequest.post("/web_scraper/", { url });

      const text = get(response, ["data", "text"], "");

      return text;
    } else if (step.type === "MODAL") {
      const action = get(step, "action", "show");

      if (action === "show") {
        const customBlockId = get(step, "blockId", null);
        setModal({ ...step, customBlock: customBlockId });
        return;
      } else {
        setModal(null);
        return;
      }
    }

    // NAVIGATE
    else if (step.type === "NAVIGATE") {
      const destination = get(step, "destination", "page");

      const urlParams = get(step, "urlParams", []);

      const addParams = (urlOrPath, params) => {
        // Determine if the input is a full URL or a path
        let isFullUrl =
          urlOrPath.startsWith("http://") || urlOrPath.startsWith("https://");

        // If it's a full URL, use URL and URLSearchParams to handle the query params
        if (isFullUrl) {
          let urlObj = new URL(urlOrPath);
          params.forEach((param) => {
            urlObj.searchParams.append(param.key, processText(param.value));
          });
          return urlObj.toString();
        } else {
          // If it's a path, manually append the query params
          let [path, search] = urlOrPath.split("?");
          let queryParams = new URLSearchParams(search);

          params.forEach((param) => {
            queryParams.append(param.key, processText(param.value));
          });

          return `${path}?${queryParams.toString()}`;
        }
      };

      // EXTERNAL URL
      if (get(step, "page") === "externalLink" || destination === "url") {
        const link = processText(get(step, "externalLink"), context);

        const resolvedLink = addParams(link, urlParams);

        if (step?.openNewWindow) {
          window.open(processText(resolvedLink), "_blank");
        } else {
          window.location.href = processText(resolvedLink);
        }
        return;
      }

      // if (get(step, "routeType") === "mailto_link") {
      //   const data = {
      //     email: get(step, "email"),
      //     subject: get(step, "subject"),
      //     body: get(step, "body"),
      //   };
      //   const mailtoLink = `mailto:${data.email}?subject=${data.subject}&body=${data.body}`;
      //   window.location.href = mailtoLink;
      //   return;
      // }

      let resolvedRoute = processText(get(step, "route"), context);

      // NAVIGATE TO INTERNAL PAGE
      if (destination === "page") {
        const matchingPage = pages.find((p) => p.id === get(step, "page"));
        if (matchingPage) {
          resolvedRoute = addSlash(get(matchingPage, "route"));
        }
      }

      // NAVIGATE TO INTERNAL FORM
      if (destination === "form") {
        const form = forms.find((f) => f.id === get(step, "form"));
        if (form) {
          resolvedRoute = addSlash(get(form, "route"));
        }
      }

      // Add params
      resolvedRoute = addParams(resolvedRoute, urlParams);

      let finalRoute = addSlash(resolvedRoute);
      if (step?.openNewWindow) {
        window.open(finalRoute, "_blank");
        return;
      } else {
        navigate(finalRoute);
        setModalStack([]);

        return;
      }
    } else if (step.type === "FORM_VALIDATION") {
      const inputBlocks = get(page, "blocks", [])
        .filter((b) =>
          ["Input", "Select", "Switch", "TextArea"].includes(b.componentId)
        )
        .filter((b) => b.key);

      let valObj = {};

      let formValues = {};
      inputBlocks.forEach((block) => {
        const blockVal = getFieldValidation(get(block, "validation", {}));
        valObj[get(block, "key")] = blockVal;

        const concatId = `${page.id}-${get(block, "key")}`;
        const blockFormState = get(formState, concatId, "");

        formValues[get(block, "key")] = blockFormState;
      });

      const validationErrors = validateFields({
        validationSchema: valObj,
        values: formValues,
        hasSubmitted: true,
      });

      const showErrors = get(step, "showErrors", true);
      if (showErrors && validationErrors) {
        setFormValidation(validationErrors);
      }

      return isEmpty(validationErrors);
    } else if (step.type === "FORM_VALUES") {
      const clearValues = get(step, "clear", false);

      if (clearValues) {
        setAllFormState({});
        setFormState({});
        return;
      }

      const formValues = get(step, "values", []);

      let formValuesObj = {};
      formValues.forEach((item) => {
        formValuesObj[get(item, "key")] = processText(get(item, "value"));
      });

      const blockIds = safeString(get(step, "blocks", ""))
        .split(",")
        .map((bId) => bId.trim())
        .map((bId) => parseInt(bId));

      const pageBlocks = get(page, "blocks", []);

      if (blockIds.length > 0) {
        let newFormValues = {
          ...allFormState,
        };

        blockIds.forEach((bId) => {
          const matchingBlock = pageBlocks.find((b) => b.id === bId);

          const isInputBlock = [
            "Input",
            "Select",
            "Switch",
            "TextArea",
          ].includes(get(matchingBlock, "componentId"));

          let finalId = bId;
          if (isInputBlock) {
            finalId = `${page.id}-${get(matchingBlock, "key")}`;
            newFormValues[finalId] = formValuesObj[get(matchingBlock, "key")];
          } else {
            newFormValues[finalId] = formValuesObj;
          }
        });

        setAllFormState(newFormValues);
        setFormState(newFormValues);
      }

      return;
    }

    // OPENAI
    else if (step.type === "OPEN_AI") {
      let model = get(step, "model");
      const hasApiKey = get(activeApp, "open_ai_api_key_configured");

      if (!hasApiKey) {
        model = "gpt-4o-mini";
      }

      const examples = get(step, "examples", []).map((item) => ({
        prompt: processText(get(item, "prompt")),
        response: processText(get(item, "response")),
      }));

      const objectSchema = get(step, "objectSchema", {});

      // Also add hidden filters?
      const dataSourceContext = get(step, "dataSourceContext", {});
      const contextMaxCharacters = get(step, "contextMaxCharacters", 5000);

      // NEW
      apiRequest.post("/openai_action/", {
        prompt: processText(get(step, "prompt")),
        response_type: get(step, "responseType", "text"),
        model,
        examples,
        data_source_id: dataSourceContext,
        max_characters: contextMaxCharacters,
        object_schema: objectSchema,
        instance_id: instanceId,
      });
    }

    // GENERATE RECORDS
    else if (step.type === "AI_GENERATE_RECORDS") {
      let model = get(step, "model");
      const hasApiKey = get(activeApp, "open_ai_api_key_configured");

      if (!hasApiKey) {
        model = "gpt-4o-mini";
      }

      const examples = get(step, "examples", []).map((item) => ({
        prompt: processText(get(item, "prompt")),
        response: processText(get(item, "response")),
      }));

      const sheet = savedSpreadsheets.find(
        (s) => s.id === get(step, "dataSource")
      );

      // LOCAL FIELD DATA
      const value = get(step, "fieldData", {});
      const config = get(value, "config", {});

      const headers = get(sheet, "headers", []);

      const objectSchema = headers
        .filter((h) => {
          const match = get(config, h, null);
          return !get(match, "value") && get(match, "active") !== false;
        })
        .map((h) => {
          const match = get(config, h, null);
          return {
            key: h,
            value: get(match, "value", null),
            description: get(match, "description", null),
          };
        });

      const mode = get(step, "mode", "multiple_records");

      setSpinner({ text: null });

      // NEW
      apiRequest.post("/openai_action/", {
        prompt: processText(get(step, "prompt")),
        response_type: mode,
        model,
        examples,
        object_schema: objectSchema,
        instance_id: instanceId,
      });
    } else if (step.type === "LOGIC") {
      if (step.operation === "addToCommaSeparatedString") {
        const list = processText(get(step, "list", ""))
          .split(",")
          .filter(Boolean); // Filter out empty strings

        const value = processText(get(step, "value"));

        let newList = [...list];

        if (!step.uniqueValues || !newList.includes(value)) {
          newList.push(value);
        }

        return newList.join(",");
      } else if (step.operation === "removeFromCommaSeparatedString") {
        const list = processText(get(step, "list", ""))
          .split(",")
          .filter(Boolean); // Filter out empty strings

        const value = processText(get(step, "value"));

        let newList = list.filter((item) => item !== value);

        return newList.join(",");
      }
    }

    // LOCAL STATE
    else if (step.type === "LOCAL_STATE") {
      const clearValues = get(step, "clear", false);

      if (clearValues) {
        clearLocalState();
        return;
      }

      let resolvedObject = {};
      get(step, "values", {}).forEach((item) => {
        const key = processText(get(item, "key"));
        const value = processText(get(item, "value"));
        resolvedObject[key] = value;
      });
      setLocalState(resolvedObject);

      // Return updated temp local state
      return {
        tempLocalState: {
          ...get(context, "tempLocalState", {}),
          ...resolvedObject,
        },
      };
    }
    // UPDATE ACTIVE USER
    else if (step.type === "UPDATE_USER") {
      if (!user) {
        return;
      }

      let resolvedObject = {};
      const values = get(step, "values", {});
      Object.keys(values).forEach((k) => {
        const value = processText(get(values, k));
        resolvedObject[k] = value;
      });

      const usersSheetId = get(activeApp, [
        "users_sheet_config",
        "spreadsheet_id",
      ]);

      if (usersSheetId) {
        const usersSheetHeaders = get(
          savedSpreadsheets.find((s) => s.id === usersSheetId),
          "headers",
          []
        );

        let updateValues = [];
        Object.keys(resolvedObject)
          .filter((k) => usersSheetHeaders.includes(k))
          .forEach((k) => {
            updateValues.push({ key: k, value: get(resolvedObject, k) });
          });

        if (updateValues.length > 0) {
          // UPDATE USER SHEET
          updateRecord({
            sheetId: usersSheetId,
            recordId: get(user, "frontly_id"),
            values: updateValues,
          });
        }
      }

      apiRequest.post("/update_user/", resolvedObject);

      setUser(resolvedObject);
      return;
    }
    // WEBHOOK
    else if (step.type === "WEBHOOK") {
      const runLocation = get(step, "runLocation", "frontEnd");

      // BODY
      let body = {};
      get(step, "body", []).forEach((item) => {
        const key = get(item, "key");
        const value = processText(get(item, "value"));
        body[key] = value;
      });

      let customBody = processText(get(step, "customBody", ""));

      if (isValidJson(customBody)) {
        customBody = JSON.parse(customBody);
      } else {
        customBody = {};
      }

      const resolvedUrl = processText(get(step, "url"));

      // HEADERS
      let headers = {};
      let customHeaders = [];

      get(step, "headers", []).forEach((item) => {
        const key = get(item, "key");
        const value = processText(get(item, "value"));
        headers[key] = value;
        customHeaders.push({ key, value });
      });

      let mergedBody = {
        ...body,
        ...customBody,
      };

      if (step.waitForRealtimeWebhook) {
        mergedBody = {
          ...mergedBody,
          instance_id: instanceId,
          app_id: get(activeApp, "id"),
        };
      }

      if (runLocation === "frontEnd") {
        // FRONT END
        try {
          const method = get(step, "method", "POST").toUpperCase();

          if (step.waitForResponse) {
            // RUN AND WAIT
            const res = await apiRequestWithCustomHeaders(customHeaders)[
              method.toLowerCase()
            ](
              resolvedUrl,
              method === "POST" ? mergedBody : { params: mergedBody }
            );
            const responseData = get(res, "data", {});
            return responseData;
          } else {
            // RUN WITHOUT WAITING
            apiRequestWithCustomHeaders(customHeaders)[method.toLowerCase()](
              resolvedUrl,
              method === "POST" ? mergedBody : { params: mergedBody }
            );
          }
        } catch (error) {}
      } else {
        // BACK END

        if (step.waitForResponse) {
          // RUN AND WAIT
          const res = await apiRequest.post("/webhook/", {
            body: mergedBody,
            headers,
            url: resolvedUrl,
          });
          return get(res, "data");
        } else {
          // RUN WITHOUT WAITING
          apiRequest.post("/webhook/", {
            method: get(step, "method", "POST"),
            body: mergedBody,
            headers,
            url: resolvedUrl,
          });
        }
      }
    }

    // CSV DOWNLOAD
    else if (step.type === "CSV_DOWNLOAD") {
      const blockId = get(step, "block");
      const block = get(page, "blocks", []).find(
        (b) => b.id === parseInt(blockId)
      );
      const sheet = savedSpreadsheets.find(
        (s) => s.id === get(block, "spreadsheet")
      );

      const headers = get(sheet, "headers", []).filter(
        (h) => !["frontly_data"].includes(h)
      );

      const headersTrimmed = get(step, "headers", "").trim();

      const sheetData = get(spreadsheets, blockId, []);
      const selectedHeaders = headersTrimmed
        .split(",")
        .map((h) => h.trim())
        .filter((h) => h !== "");

      const finalHeaders =
        selectedHeaders.length > 0 ? selectedHeaders : headers;

      if (headers.length > 0 && sheetData.length > 0) {
        downloadAsCsv(sheetData, finalHeaders, get(step, "fileName"));
      }
    } else if (step.type === "JAVASCRIPT") {
      const javascript = get(step, "javascript");

      const data = {
        step: context,
      };

      const processedJavascript = processText(javascript);
      // console.log("processedJavascript", processedJavascript);

      // EXAMPLE 1: return step['1'].map(record =>  record.name + ' is ' + 'cool').join(', ');
      // EXAMPLE 2: return step['1'].map(function(record, index) { return record.name + ' is ' + index; }).join(', ');
      // EXAMPLE 3: return 'Hello, my name is {{user.email}} and my favorite products are ' + {{block.2}}.map(record => record.name).join(', ');

      const dynamicFunction = new Function("step", processedJavascript);
      const result = dynamicFunction(data);
      // console.log("result", result);

      return result;
    }

    // TIME DELAY
    else if (step.type === "TIME_DELAY") {
      const delayInSeconds = get(step, "seconds", 1);

      // Delay X seconds then return
      await new Promise((resolve) =>
        setTimeout(resolve, delayInSeconds * 1000)
      );

      return;
    }

    // MAKE
    else if (step.type === "MAKE") {
      let resolvedObject = {};
      get(step, "values", {}).forEach((item) => {
        const key = get(item, "key");
        const value = processText(get(item, "value"));
        resolvedObject[key] = value;
      });

      const res = await apiRequest.post("/make_scenarios/", {
        scenario_id: get(step, ["scenario", "id"]),
        values: resolvedObject,
      });
      return;
    }

    // MAKE
    else if (step.type === "ZAPIER") {
      let resolvedObject = {};
      get(step, "values", {}).forEach((item) => {
        const key = get(item, "key");
        const value = processText(get(item, "value"));
        resolvedObject[key] = value;
      });

      const response = await axios({
        method: "get", // or 'post', depending on your Lambda function
        url: step.webhookUrl,
        params: resolvedObject,
      });

      return;
    }

    // SEND EMAIL
    else if (step.type === "EMAIL") {
      const emailObject = {
        body: processText(get(step, "body")),
        sender_email: processText(get(step, "senderEmail")),
        sender_name: processText(get(step, "senderName")),
        subject: processText(get(step, "subject")),
        recipient_emails: processText(get(step, "recipientEmails")),
      };

      apiRequest.post("/send_email/", emailObject);

      return;
    } else if (step.type === "SPINNER") {
      if (get(step, "action", "show") === "show") {
        // SHOW SPINNER
        const text = processText(get(step, "text"));
        setSpinner({ text });
        return;
      } else {
        // HIDE SPINNER
        setSpinner(null);
      }
    }

    // NOTIFICATION
    else if (step.type === "NOTIFICATION") {
      const message = processText(get(step, "text"));
      const type = get(step, "notificationType", "SUCCESS");
      if (type === "SUCCESS") {
        successNotification(message);
      } else if (type === "ERROR") {
        errorNotification(message);
      }
      return;
    }
    // COPY TO CLIPBOARD
    else if (step.type === "COPY_TO_CLIPBOARD") {
      const value = processText(get(step, "value"));
      navigator.clipboard.writeText(value);
    }
    // LOGOUT
    else if (step.type === "LOGOUT") {
      clearConfig();
      Cookies.remove("accessToken");
      localStorage.removeItem("appId");
      successNotification("Logged out.");
      navigate("/login");
    }
  };

  const handleCustomAction = async (data) => {
    const {
      action,
      context,
      blockId,
      startFromStepId = null,
      startFromStepCount = 1,
      setHasAsyncStep,
      rawAction = null,
      reusableBlockId = null,
      //
      formId,
      formActionType,

      // to prevent fetching if not firing a 'fetching' kind of action
      couldFetch,
      hasStartedFetching,
      setHasStartedFetching,
    } = data;

    let finalContext = { ...context };

    let tempLocalState = get(context, "tempLocalState", {});

    if (action) {
      let steps = migrateActionSteps(get(action, "steps", []));

      // This only works because the inputMap is passed into the data object of all blocks within a custom block
      // In this case the 'page' object is the mock page object that contains the custom block data
      const inputMap = get(page, ["blocks", 0, "inputMap"], {});

      if (!isEmpty(inputMap)) {
        // PROCESS STEPS FOR CUSTOM BLOCK MAPPING
        steps = steps.map((s) => {
          const concatInit = `action-${action.id}-${s.id}`;

          let mappedStep = { ...s };

          if (s.type === "NAVIGATE") {
            const mappedPageId = get(inputMap, `${concatInit}-page`);
            if (mappedPageId) {
              mappedStep["page"] = mappedPageId;
            }
          } else if (s.type === "GOOGLE") {
            const mappedSpreadsheetId = get(
              inputMap,
              `${concatInit}-spreadsheet`
            );
            if (mappedSpreadsheetId) {
              mappedStep["spreadsheet"] = mappedSpreadsheetId;
            }
          }

          return mappedStep;
        });

        finalContext = {
          ...finalContext,
          input: { ...get(finalContext, "input", {}), ...inputMap },
        };
      }

      let allStepsComplete = false;

      let currentStepId = startFromStepId;

      let stepCount = startFromStepCount;

      while (steps.length > 0 && !allStepsComplete) {
        const instanceId = (
          Math.random().toString(36) + Math.random().toString(36)
        ).substr(2, 12);

        // Find step
        let step = null;
        let nextStep = null;

        if (currentStepId) {
          step = steps.find((step) => step.id === currentStepId);
        } else {
          // Find all steps that have no parent, then find the first passing step
          step = steps
            .filter((s) => !s.parent)
            .find((s) =>
              passesDisplayConditions({
                conditions: get(s, "conditions", []),
                context: finalContext,
              })
            );
        }

        if (step) {
          if (
            couldFetch &&
            !hasStartedFetching &&
            (asyncActionTypes.includes(step.type) || step.type === "GOOGLE")
          ) {
            addToFetchingBlocks([blockId]);
            setHasStartedFetching();
          }

          const stepResponse = await handleActionStep(
            step,
            finalContext,
            blockId,
            instanceId,
            reusableBlockId
          );

          const newTempLocalState = get(stepResponse, "tempLocalState", {});

          if (!isEmpty(newTempLocalState)) {
            tempLocalState = newTempLocalState;
          }

          finalContext = {
            ...finalContext,
            // keeping this work for now, probably will deprecate and move to a new system that uses ID or something
            step: {
              ...get(finalContext, "step", {}),
              [stepCount]: stepResponse,
            },
            actionSteps: {
              ...get(finalContext, "actionSteps", {}),
              [stepCount]: stepResponse,
            },
            tempLocalState,
          };

          // Finds the first child step that passes the run conditions
          nextStep = steps
            .filter((child) => child.parent && child.parent === step.id)
            .find((child) =>
              passesDisplayConditions({
                conditions: get(child, "conditions", []),
                context: finalContext,
              })
            );
        }

        const stepType = get(step, "type");

        const isGenerateRecords = ["AI_GENERATE_RECORDS"].includes(stepType);

        // IF there's no next step, then no point in waiting for response, EXCEPT for the generate Records since we handle that
        if (nextStep || isGenerateRecords) {
          const waitForAsyncResponse =
            get(step, "waitForRealtimeWebhook") ||
            (get(nextStep, "waitForResponse", false) &&
              get(nextStep, "asyncResponse", false)) ||
            ["OPEN_AI", "AI_GENERATE_RECORDS"].includes(stepType);

          if (asyncActionTypes.includes(stepType) && waitForAsyncResponse) {
            allStepsComplete = true;

            if (setHasAsyncStep) {
              setHasAsyncStep();
            }

            setFormIsFetching(formId);

            setWebsocketRequests([
              ...websocketRequests,
              {
                formId,
                formActionType,
                actionId: action.id,
                step,

                // The count of the current step
                currentStepCount: stepCount,

                // The ID of the step that's currently waiting for a response
                currentStepId: step.id,

                // The count of the next step
                nextStepCount: stepCount + 1,

                // The ID of the next step that will be run after the response is received
                nextStepId: get(nextStep, "id"),

                instanceId,
                context: finalContext,
                blockId,
                rawAction,
              },
            ]);
          } else {
            currentStepId = get(nextStep, "id");
            stepCount += 1;
          }
        } else {
          allStepsComplete = true;
        }
      }
    }
  };

  const actionResolver = async (data) => {
    const {
      rawAction,
      actionId,
      context,
      blockId = null,
      preventFetching = false,
      startFromStepId = null,
      startFromStepCount = 1,
      hasStartedFetchingInit = false,
      reusableBlockId,
      responseType,
    } = data;

    if (!actionId && !rawAction) {
      return null;
    }

    let hasStartedFetching = hasStartedFetchingInit;

    let hasAsyncStep = false;

    const action = rawAction || actions.find((item) => item.id === actionId);
    const stepTypes = get(action, "steps", []).map((s) => s.type);

    const includesRefreshBlocks = stepTypes.includes("REFRESH_BLOCKS");
    const couldFetch = blockId && !preventFetching && !startFromStepId;

    try {
      await handleCustomAction({
        rawAction,
        action,
        context,
        blockId,
        startFromStepId,
        startFromStepCount,
        reusableBlockId,
        couldFetch,
        hasStartedFetching,
        setHasStartedFetching: () => {
          hasStartedFetching = true;
        },
        setHasAsyncStep: () => {
          hasAsyncStep = true;
        },
      });
    } catch (error) {
      console.error("Error in handleCustomAction:", error);
    }

    if (
      !hasAsyncStep &&
      !includesRefreshBlocks &&
      !preventFetching &&
      hasStartedFetching
    ) {
      // This is an ultra hack just for Shay Rosen because I can't justify the time to understand
      // Why this is happening
      const skipClear = get(activeApp, "organization") === 4702;
      if (!skipClear) {
        clearFetchingBlocks();
      }
    }
  };
  return { actionResolver, handleCustomAction };
};

export default useActionResolver;
