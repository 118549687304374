import { get, startCase } from "lodash";
import { passesCondition, safeArray, safeString } from "app/utils/utils";

import { sortRecords } from "../Table";

// Get form fields (used for static fields and forms throughout the app)
export const getFormFields = (data) => {
  const {
    dataSourceId,
    relations,
    processDynamicText,
    appDateFormat = {},
    relatedHeaders = [],
    relationColumns = {},
    sheetHeaders = [],
    sheetFieldData = {},
    localFieldData = {},
    valuesObject = {},
    sheetOrder = [],
    localOrder,
    conditionsObject = null,
    showInactive = false,
    block = {},
    fieldDefault = { componentId: "Input" },
  } = data;

  // GET FINAL ORDER
  let order = [];
  if (safeArray(localOrder).length > 0) {
    order = localOrder;
  } else if (safeArray(sheetOrder).length > 0) {
    order = sheetOrder;
  }

  // Combine relation headers and regular headers
  let combinedHeaders = [...sheetHeaders, ...relatedHeaders];

  const sortedHeaders = [
    ...order.filter((o) => combinedHeaders.includes(o)),
    ...combinedHeaders.filter((h) => !order.includes(h)),
  ].filter((h) => {
    if (h.includes("frontly_id")) {
      if (h.includes("frontly_id__")) {
        return true;
      }
    }

    return !h.includes("frontly_id");
  });

  let fields = sortedHeaders
    .map((h) => {
      const sheetDateFormat = get(sheetFieldData, [h, "dateFormat"], {});
      const localDateFormat = get(localFieldData, [h, "dateFormat"], {});
      const mergedDateFormat = {
        ...appDateFormat,
        ...sheetDateFormat,
        ...localDateFormat,
      };

      const sheetValidation = get(sheetFieldData, [h, "validation"], {});

      let fieldObj = {
        key: h,
        label: startCase(h),
        active: true,
        ...fieldDefault,
        type: "text",
        value: get(valuesObject, h),
        ...get(sheetFieldData, h, {}),
        ...get(localFieldData, h, {}),
        dateFormat: mergedDateFormat,
        // merge sheet level validation with local level validation
        ...sheetValidation,
      };

      // Handle sorting select dropdown items
      let options = get(fieldObj, "options", []).map((o) => ({
        ...o,
        value: processDynamicText({
          text: o.value,
          reusableBlockId: block.reusableBlockId,
          context: {
            form: valuesObject,
            repeatingRecord: get(block, "repeatingRecord"),
          },
        }),
      }));

      const selectSorting = get(fieldObj, "selectSorting");
      if (selectSorting) {
        options = sortRecords(options, "value", selectSorting);
        fieldObj["options"] = options;
      }

      return fieldObj;
    })
    .filter((f) => showInactive || f.active)
    // Ensure all fields have an id and key
    .map((f) => ({
      ...f,
      id: get(f, "id") || get(f, "key"),
      key: get(f, "key") || get(f, "id"),
    }))
    .map((f) => {
      if (f.componentId !== "Hidden") {
        let relationColumn = get(relationColumns, f.id);
        if (relationColumn) {
          // If field has hidden filters, filter the relation column
          // This is only relevant / enabled for 'related' fields
          const hiddenFilters = safeArray(f, "hiddenFilters");
          if (hiddenFilters.length > 0) {
            const passesFilters = (r) => {
              let pass = true;

              // Hidden Filters (FOR DETAIL VIEW CHILDREN ONLY)
              hiddenFilters.forEach((hf) => {
                let hfKey = hf.key;

                // Update may 8 - data is indeed processed on the back-end but
                // for that reason, the reference does NOT need to be adjust because it's already stitched
                // Since we process the data on the back-end now, this relation reference needs to be adjusted here
                // if (hfKey.includes("__")) {
                //   const parts = hfKey.split("__");
                //   if (parts.length === 2) {
                //     hfKey = get(parts, 1);
                //   }
                // }

                const val1 = get(r, hfKey);

                const val2 = processDynamicText({
                  text: hf.value,
                  reusableBlockId: block.reusableBlockId,
                  context: {
                    form: valuesObject,
                    repeatingRecord: get(block, "repeatingRecord"),
                  },
                });
                const operator = get(hf, "operator", "equals");

                const isValid =
                  val2 ||
                  ["exists", "does_not_exist", "is_true", "is_false"].includes(
                    operator
                  );

                if (
                  isValid &&
                  !passesCondition({ value1: val1, value2: val2, operator })
                ) {
                  pass = false;
                }
              });

              return pass;
            };

            relationColumn = relationColumn.filter((item) =>
              passesFilters(item)
            );
          }

          // Support multi-select if set that way for comma-separated relations
          let componentId =
            f.componentId === "MultiSelect" ? "MultiSelect" : "Select";

          // Handle supabase autocomplete
          const isDataBase =
            dataSourceId && safeString(dataSourceId).includes("db__");

          if (isDataBase) {
            const matchingRelation = relations.find(
              (r) => r.sheet2 === dataSourceId && r.column2 === f.id
            );

            return {
              ...f,
              componentId: "Autocomplete",
              matchingRelation,
            };
          }

          return { ...f, componentId, options: relationColumn };
        }
      }

      return f;
    });

  // Run display conditions if passed in
  if (conditionsObject) {
    const { passesDisplayConditions, conditionKey } = conditionsObject;
    fields = fields.filter((f) => {
      const fieldConditions = get(f, conditionKey, []);
      return passesDisplayConditions({
        conditions: fieldConditions,
        context: {
          spreadsheetColumn: valuesObject,
          form: valuesObject,
        },
      });
    });
  }

  const displayFields = fields.filter(
    (f) => showInactive || f.componentId !== "Hidden"
  );
  const hiddenFields = fields.filter((f) => f.componentId === "Hidden");

  return { displayFields, hiddenFields, displayOrder: sortedHeaders };
};

export const sizeObject = {
  medium: "bodyMd",
  large: "bodyLg",
};

export const paddingObject = {
  medium: "10px",
  large: "15px",
};

export const labelStyleObject = {
  medium: "bodySm",
  large: "bodyMd",
};

export const gridRowGapObject = {
  medium: "16px",
  large: "18px",
};

export const gridColumnGapObject = {
  medium: "26px",
  large: "32px",
};
