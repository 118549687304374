import { getPixels, isFrontlyAdmin } from "app/utils/utils";
import { rDarkMode, rFormState, rFormValidation } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { TextArea as TextAreaComponent } from "app/components";
import { get } from "lodash";
import { getInputStyles } from "app/utils/inputUtils";

const TextArea = ({ page, block }) => {
  const darkMode = useRecoilValue(rDarkMode);
  const [formState, setFormState] = useRecoilState(rFormState);

  const fieldId = get(block, "key");
  const concatId = `${page.id}-${fieldId}`;

  const formValidation = useRecoilValue(rFormValidation);
  const validationError = get(formValidation, block.key, null);

  const onChange = (v) => {
    setFormState((prev) => ({
      ...prev,
      [concatId]: v,
    }));
  };

  const value = get(formState, concatId, "");

  const handleKeyDown = (event) => {
    event.preventDefault();
  };

  const disabledProps = isFrontlyAdmin
    ? {
        readOnly: true,
        tabIndex: -1,
        onKeyDown: handleKeyDown,
      }
    : {};

  return (
    <TextAreaComponent
      data={{
        id: `formInput__${block.id}`,
        onChange,
        value,
        placeholder: block.placeholder,
        ...getInputStyles(block, validationError, darkMode),
        ...disabledProps,
        minHeight: getPixels(block.height),
        resize: "none",
      }}
    />
  );
};

export default TextArea;
