import { rDarkMode, rFormState, rFormValidation } from "app/utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { Select as SelectComponent } from "app/components";
import { get } from "lodash";
import { getInputStyles } from "app/utils/inputUtils";
import { isFrontlyAdmin } from "app/utils/utils";

const Select = ({ page, block }) => {
  const darkMode = useRecoilValue(rDarkMode);
  const [formState, setFormState] = useRecoilState(rFormState);

  const formValidation = useRecoilValue(rFormValidation);
  const validationError = get(formValidation, block.key, null);

  const fieldId = get(block, "key");
  const concatId = `${page.id}-${fieldId}`;

  const onChange = (v) => {
    setFormState((prev) => ({
      ...prev,
      [concatId]: v,
    }));
  };

  const value = get(formState, concatId, "");
  const options = get(block, "options", []);

  return (
    <SelectComponent
      data={{
        onChange,
        options,
        value,
        selectText: get(block, "selectText"),
        ...getInputStyles(block, validationError, darkMode),
        disabled: isFrontlyAdmin,
      }}
    />
  );
};

export default Select;
