import { MenuItem, Select as MuiSelect } from "@mui/material";
import { getPixels, parseIntFromPixels } from "app/utils/utils";

import InputBase from "@mui/material/InputBase";
import { colors } from "app/utils/theme";
import { get } from "lodash";
import { rTranslations } from "app/utils/recoil";
import { styled } from "@mui/material/styles";
import { useRecoilValue } from "recoil";

export const BootstrapInput = styled(InputBase)(({ theme, ...props }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    height: `${parseIntFromPixels(props.height) - 31}px`,
    borderRadius: getPixels(props.borderRadius || 8),
    position: "relative",
    backgroundColor: props.backgroundColor || "white",
    border: props.border || `1px solid ${colors.pitchBorder}`,
    fontSize: props.fontSize || 14,
    color: props.color,
    padding: props.padding || "7px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: getPixels(props.borderRadius || 8),
      borderColor: "1px solid rgb(225, 228, 232)",
      boxShadow: "none",
    },
  },
}));

const Select = ({ data }) => {
  const translations = useRecoilValue(rTranslations);

  const { onChange } = data;

  const options = get(data, "options", []);

  // HANDLE OBJECT OR STRING VALUE
  let value = get(data, "value") || get(data, "defaultValue") || "";

  const selectText =
    get(data, "selectText") ||
    data.placeholder ||
    get(translations, "selectText") ||
    data.noneText ||
    "None";

  let backgroundColor = data.backgroundColor || data.background;

  return (
    <div style={{ width: data.width || "100%", margin: data.margin }}>
      <MuiSelect
        displayEmpty
        disabled={data.disabled}
        renderValue={value ? null : () => selectText}
        value={value}
        sx={{
          width: "100%",
        }}
        input={
          <BootstrapInput
            height={data.height}
            backgroundColor={backgroundColor}
            border={data.border}
            padding={data.padding}
            borderRadius={data.borderRadius}
            color={data.color}
            fontSize={data.fontSize}
          />
        }
        size="small"
        onChange={(e) => onChange(e.target.value)}
      >
        {!data.hideEmptyItem && !data.required && (
          <MenuItem value="">{selectText}</MenuItem>
        )}
        {options.map((o) => (
          <MenuItem value={o.value}>{o.label}</MenuItem>
        ))}
      </MuiSelect>
    </div>
  );
};

export default Select;
