import { get, lowerCase } from "lodash";
import { getPixels, safeLower } from "app/utils/utils";
import { rApp, rTranslations } from "app/utils/recoil";

import { Icon } from ".";
import { colors } from "app/utils/theme";
import styled from "styled-components";
import { useRecoilValue } from "recoil";

export const getDisplayValue = (data) => {
  const {
    label,
    value,
    componentId,
    operator,
    translations,
    options = [],
    processDynamicText,
  } = data;

  const anyText = get(translations, "filterAny", "any");
  const isText = get(translations, "filterIs", "is");
  const between = get(translations, "between", "between");
  const and = get(translations, "and", "and");
  const selection = get(translations, "selection", "selection");
  const selections = get(translations, "selections", "selections");

  let secondPart = `${operator} ${value || anyText}`;

  if (componentId === "MultiSelect") {
    if (value) {
      const selectionCount = value.split(",").map((v) => v.trim()).length;
      secondPart = `(${selectionCount} ${
        selectionCount > 1 ? selections : selection
      })`;
    } else {
      secondPart = `${isText} ${anyText}`;
    }
  } else if (componentId === "Select") {
    const matchingOption = options.find((o) => o.value === value);

    const displayVal = get(matchingOption, "label") || value || anyText;

    secondPart = `${operator} ${displayVal}`;

    if (["equals", "date_equals"].includes(operator)) {
      secondPart = `${isText} ${displayVal}`;
    }
  } else if (componentId === "Input") {
    secondPart = `${operator}`;
    if (operator === "equals") {
      secondPart = isText;
    }
    secondPart = lowerCase(secondPart);
  } else if (componentId === "Boolean") {
    secondPart = `${isText} ${safeLower(value || anyText)}`;
  } else if (["DateRangePicker", "NumericRange"].includes(componentId)) {
    if (value) {
      const [start, end] = value.split("|");
      secondPart = `${between} ${start} ${and} ${end}`;
    } else {
      secondPart = `${isText} ${anyText}`;
    }
  } else if (["equals", "date_equals"].includes(operator)) {
    secondPart = `${isText} ${value || anyText}`;
  } else if (operator.includes("date")) {
    secondPart = `${operator.replace("date_", "")} ${value || anyText}`;
  }

  const operatorTranslated = get(translations, operator, operator);

  let displayValue = `${label} ${secondPart}`.replace(
    operator,
    operatorTranslated
  );

  return processDynamicText({
    text: displayValue,
  });
};

const CustomFilterButton = ({
  icon,
  background,
  border,
  displayValue,
  iconColor,
  textColor,
  onClick,
}) => {
  const activeApp = useRecoilValue(rApp);

  const inputRadius = get(activeApp, ["styling", "formInputRadius"], 8);

  return (
    <Container
      onClick={onClick}
      background={background}
      border={border}
      borderRadius={inputRadius}
    >
      {icon && (
        <Icon
          data={{
            icon,
            size: 17,
            margin: "0 2px 0 0",
            color: iconColor,
          }}
        />
      )}
      <Text color={textColor}>{displayValue}</Text>
    </Container>
  );
};

export default CustomFilterButton;

export const CustomFilterInput = ({
  icon,
  value,
  displayValue,
  showLabel = true,
  onChange,
  background,
  border,
  iconColor,
  textColor,
  placeholder,
  width,
}) => {
  const activeApp = useRecoilValue(rApp);
  const translations = useRecoilValue(rTranslations);

  const inputRadius = get(activeApp, ["styling", "formInputRadius"], 8);
  const anyText = get(translations, "filterAny", "any");

  return (
    <Container
      style={{ padding: icon && showLabel ? "0px" : "0px 0px 0px 10px" }}
      noHover
      background={background}
      border={border}
      borderRadius={inputRadius}
    >
      {icon && (
        <Icon
          data={{
            icon,
            size: 17,
            margin: "8px 2px 8px 8px",
            color: iconColor,
          }}
        />
      )}
      {showLabel !== false && (
        <Text color={textColor} style={{ cursor: "default" }}>
          {displayValue}
        </Text>
      )}
      <Input
        width={width}
        color={textColor}
        value={value}
        placeholder={placeholder || anyText}
        onChange={(e) => onChange(e.target.value)}
      />
    </Container>
  );
};

const Input = styled.input`
  border: 0px;
  background: transparent;
  font-size: 15px;
  padding: 5px 5px 5px 2px;
  font-weight: 500;
  font-size: 14px;
  width: ${(p) => p.width || "100px"};
  color: ${(p) => p.color};
  outline: 0px;
  &::placeholder {
    color: ${colors.grey3};
  }
`;

const Container = styled.div`
  padding: 10px;
  background: ${(p) => p.background};
  border: ${(p) => p.border};
  border-radius: ${(p) => getPixels(p.borderRadius || 10)};
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  height: 38px;
  ${(p) =>
    !p.noHover &&
    `
    &:hover {
      filter: brightness(95%);
    }
  `}
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: ${(p) => p.color};
`;
