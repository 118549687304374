import { get, isArray, isObject } from "lodash";
import {
  getCurrentDomain,
  getDuplicates,
  getGoogleSheetsEndpoint,
  safeArray,
  safeString,
} from "./utils/utils";
import {
  rApp,
  rFetchingBlockIds,
  rSavedSpreadsheets,
  spreadsheetsSelector,
} from "./utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { apiRequest } from "./utils/apiRequests";
import { successNotification } from "./utils/Notification";
import useActiveBlock from "./utils/useActiveBlock";
import usePage from "./utils/usePage";
import useSetPage from "./utils/useSetPage";
import useUtils from "./renderingApp/useUtils";

const statusKeys = ["status", "role", "label", "position", "type", "city"];

const findNumericKey = (obj) => {
  for (let key in obj) {
    if (key !== "frontly_id") {
      let value = obj[key];
      // If value is a number or can be converted to a number (excluding NaN)
      if (!isNaN(parseFloat(value)) && isFinite(value)) {
        return key;
      }
    }
  }
  return null; // return null if no numeric key is found
};

export const getHeaders = (spreadsheet) => {
  const fieldData = get(spreadsheet, ["field_data", "config"], {});

  const headers = get(spreadsheet, "headers", [])
    .filter((k) => !["frontly_data", "frontly_id", "id"].includes(k))
    .filter((h) => get(fieldData, [h, "active"]) !== false);

  const spreadsheetData = get(spreadsheet, "data", []);

  const imageKeys = headers.filter(
    (k) =>
      k.toLowerCase().includes("image") ||
      k.toLowerCase().includes("url") ||
      k.toLowerCase().includes("img")
  );

  const firstItem = get(spreadsheetData, 0, {});

  const numericKey = findNumericKey(firstItem);

  const nonImageHeaders = headers.filter((h) => !imageKeys.includes(h));
  const validDisplayHeaders = headers.filter((h) => !h.includes("id"));

  const labelKey = get(validDisplayHeaders, 0);

  return {
    imageKeys,
    nonImageHeaders,
    validDisplayHeaders,
    labelKey,
    numericKey,
  };
};

const useSpreadsheetRequests = () => {
  const activeApp = useRecoilValue(rApp);

  const { setBlock } = useSetPage();

  const activeBlock = useActiveBlock();

  // const page = useRecoilValue(rPage);
  const page = usePage();

  const { processDynamicText } = useUtils();

  const [savedSpreadsheets, setSavedSpreadsheets] =
    useRecoilState(rSavedSpreadsheets);

  const blocks = get(page, "blocks", []);

  const [recoilSpreadsheets, setRecoilSpreadsheets] =
    useRecoilState(spreadsheetsSelector);

  const [fetchingBlockIds, setFetchingBlockIds] =
    useRecoilState(rFetchingBlockIds);

  const getDomain = () => {
    const subdomain = get(activeApp, "subdomain");
    if (subdomain) {
      return `${subdomain}.frontly.ai`;
    }
    return getCurrentDomain();
  };

  const refreshSheet = async ({ sheetId, blockId, refreshObject }) => {
    if (blockId) {
      setFetchingBlockIds([...fetchingBlockIds, blockId]);
    }

    await getRecords({
      sheetId,
      refreshObject,
    }).then((sheetData) => {
      successNotification("Sheet Data Refreshed");

      setSavedSpreadsheets(
        savedSpreadsheets.map((s) => {
          if (s.id == sheetId) {
            return { ...s, ...sheetData };
          }
          return s;
        })
      );

      if (blockId) {
        setFetchingBlockIds(fetchingBlockIds.filter((id) => id !== blockId));
      }
    });
  };

  // GET SINGLE RECORD
  const getRecord = async ({ sheetId, recordId, rowIdColumn }) => {
    const body = {
      spreadsheet_id: sheetId,
      row_id_column: rowIdColumn,
      row_id: processDynamicText({ text: recordId }),
      method: "GET_ROW",
      domain: getDomain(),
    };

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      if (safeString(sheetId).includes("db__")) {
        const data = get(response, "data", {});
        return data;
      } else {
        const data = get(response, ["data", "data"], {});
        // const headers = get(response, ["data", "headers"], []);
        return data;
      }
    });
  };

  // GET ALL RECORDS
  const getRecords = async ({
    sheetId,
    filters,
    pagination,
    refreshObject = null,
  }) => {
    const body = {
      spreadsheet_id: sheetId,
      method: "GET_ALL",
      domain: getDomain(),
      filters,
      pagination,
      refresh_object: refreshObject,
    };

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      const data = get(response, ["data", "data"], []);
      const headers = get(response, ["data", "headers"], []);
      const fieldData = get(response, ["data", "field_data"], {});
      return { data, headers, field_data: fieldData };
    });
  };

  const updateMatchingSheets = ({
    type,
    sheetId,
    recordId,
    values,
    newRecord,
    newRecords,
    currentRecord = {},
  }) => {
    // TRY FINDING ALL INSTANCES OF THIS RECORD IN USE AND UPDATE IT IN REAL TIME
    try {
      let processedRecordId = processDynamicText({ text: recordId });

      let newSpreadsheets = {};
      // Find all the block ids that use this spreadsheet
      const blocksWithThisSheet = blocks
        .filter((b) => b.spreadsheet === sheetId)
        .map((b) => b.id);

      Object.keys(recoilSpreadsheets).forEach((blockId) => {
        const isValid = blocksWithThisSheet.includes(parseInt(blockId));

        if (isValid) {
          let match = get(recoilSpreadsheets, blockId, []);

          const isFormOrList = ["Form", "InfoList"].includes(
            get(
              blocks.find((b) => b.id === parseInt(blockId)),
              "componentId"
            )
          );

          if (isFormOrList) {
            // FORM - to update the 'block' variable
            if (type === "update") {
              let newData = { ...match };
              values.forEach((v) => {
                newData[v.key] = v.value;
              });
              newSpreadsheets[blockId] = newData;
            }
          } else {
            // Force to array if not already
            match = safeArray(match);

            // NOT FORM
            let newData = [...match];
            if (type === "create_multiple") {
              // Add new record to existing records
              newData = [...newRecords, ...match];
            } else if (type === "create") {
              // Add new record to existing records
              newData = [newRecord, ...match];
            } else if (type === "delete") {
              // Remove matching existing record
              newData = match.filter((r) => r.frontly_id != processedRecordId);
            } else if (type === "update") {
              // Update matching existing record
              newData = match.map((r) => {
                if (r.frontly_id == processedRecordId) {
                  let newValues = { ...r, ...currentRecord };
                  values.forEach((v) => {
                    newValues[v.key] = v.value;
                  });
                  return newValues;
                }
                return r;
              });
            }

            newSpreadsheets[blockId] = newData;
          }
        }
      });

      setRecoilSpreadsheets(newSpreadsheets);
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  // UPDATE ROW
  const updateRecord = async ({
    sheetId,
    recordId,
    values,
    rowIdColumn,
    currentRecord = {},
  }) => {
    const body = {
      spreadsheet_id: sheetId,
      row_id: processDynamicText({ text: recordId }),
      row_id_column: rowIdColumn,
      method: "UPDATE_ROW",
      domain: getCurrentDomain(),
      values,
    };

    updateMatchingSheets({
      type: "update",
      sheetId,
      recordId,
      values,
      currentRecord,
    });

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      const data = get(response, "data", {});
      return data;
    });
  };

  // CREATE ROW
  const createRecord = async ({ sheetId, values }) => {
    const body = {
      spreadsheet_id: sheetId,
      method: "CREATE_ROW",
      domain: getCurrentDomain(),
      values,
    };

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      const newRecord = get(response, ["data"], {});
      updateMatchingSheets({ type: "create", sheetId, newRecord });
      return newRecord;
    });
  };

  // DELETE ROW
  const deleteRecord = async ({ sheetId, recordId, rowIdColumn }) => {
    const body = {
      spreadsheet_id: sheetId,
      row_id: recordId,
      row_id_column: rowIdColumn,
      method: "DELETE_ROW",
      domain: getCurrentDomain(),
    };

    const deletedResponse = await apiRequest.post(
      getGoogleSheetsEndpoint(),
      body
    );

    updateMatchingSheets({ type: "delete", sheetId, recordId });

    return deletedResponse;
  };

  const handleCalendar = (sheetId) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);
    const { validDisplayHeaders } = getHeaders(spreadsheet);
    const startDateColumn = validDisplayHeaders.find((h) => h.includes("date"));

    setBlock({
      ...listBlockData(sheetId),
      startDate: startDateColumn,
      eventLabel: get(validDisplayHeaders, 0),
    });
  };

  const handleStat = (sheetId) => {};

  const handleChart = (sheetId) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);
    const spreadsheetData = get(spreadsheet, "data", []);
    const { nonImageHeaders } = getHeaders(spreadsheet);

    const firstItem = get(spreadsheetData, 0, {});

    const labelKey =
      nonImageHeaders.find((h) => statusKeys.includes(h)) ||
      get(nonImageHeaders, 0);

    setBlock({
      ...listBlockData(sheetId),
      labelKey: labelKey,
      valueKey: findNumericKey(firstItem),
    });
  };

  const handleInfoList = (sheetId) => {
    const d = savedSpreadsheets.find((s) => s.id === sheetId);

    const headers = get(d, "headers", []).filter(
      (k) => !["frontly_data", "frontly_id", "id"].includes(k)
    );

    const sheet = savedSpreadsheets.find((s) => s.id === parseInt(sheetId));

    const data = {
      ...activeBlock,
      gridLayout: headers.length > 3,
      label: get(sheet, "title"),
      spreadsheet: sheetId,
    };

    setBlock(data);
  };

  const handleForm = (sheetId) => {
    const d = savedSpreadsheets.find((s) => s.id === sheetId);

    const headers = get(d, "headers", []).filter(
      (k) => !["frontly_data", "frontly_id", "id"].includes(k)
    );

    const sheet = savedSpreadsheets.find((s) => s.id === parseInt(sheetId));

    const data = {
      ...activeBlock,
      gridLayout: headers.length > 3,
      label: get(sheet, "title"),
      spreadsheet: sheetId,
    };

    setBlock(data);
  };

  const handleGrid = (sheetId) => {
    setBlock(listBlockData(sheetId));
  };

  const handleCustom = (sheetId) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);
    let data = {
      ...activeBlock,
      label: get(spreadsheet, "title"),
      spreadsheet: sheetId,
    };
    setBlock(data);
  };

  const listBlockData = (sheetId) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);

    const { validDisplayHeaders, imageKeys } = getHeaders(spreadsheet);

    let data = {
      ...activeBlock,
      label: get(spreadsheet, "title"),
      spreadsheet: sheetId,
    };

    // Add image key if it exists
    if (get(imageKeys, 0)) {
      data.image = get(imageKeys, 0);
    }

    if (activeBlock.componentId !== "Chart") {
      const styles = ["headingLg", "bodyMd", "bodySm"];
      data["fields"] = validDisplayHeaders
        .filter((h, i) => i < 3)
        .map((h, i) => ({
          id: i + 1,
          key: h,
          fontStyle: get(styles, i),
        }));
    }

    return data;
  };

  const handleKanban = (sheetId, colKey = null) => {
    const spreadsheet = savedSpreadsheets.find((s) => s.id === sheetId);
    const spreadsheetData = get(spreadsheet, "data", []);

    const { imageKeys, nonImageHeaders } = getHeaders(spreadsheet);

    const firstItem = get(spreadsheetData, 0);

    if (!isObject(firstItem) || !isArray(spreadsheetData)) {
      return [];
    }

    let columns = null;
    let duplicateColumns = [];

    const headers = Object.keys(firstItem)
      .filter((k) => !["frontly_data", "frontly_id", "id"].includes(k))
      .filter((h) => !imageKeys.includes(h));

    if (colKey) {
      const { items, dupes } = getDuplicates(spreadsheetData, colKey);
      duplicateColumns.push(colKey);
      if (dupes.length > 1) {
        columns = dupes.map((d) => ({ label: d, value: d }));
      } else {
        columns = items.map((d) => ({ label: d, value: d }));
      }
    } else {
      headers.forEach((k) => {
        const { dupes } = getDuplicates(spreadsheetData, k);

        // Duplicate value handling for kanban column
        if (dupes.length > 1) {
          duplicateColumns.push(k);

          if (!columns) {
            columns = dupes.map((d) => ({ label: d, value: d }));
          }
        }
      });
    }

    let columnKey =
      colKey ||
      duplicateColumns.find((k) => {
        statusKeys.forEach((key) => {
          if (k.includes(key)) {
            return true;
          }
        });
      }) ||
      get(duplicateColumns, 0);

    if (!columnKey) {
      columnKey =
        nonImageHeaders.find((h) => statusKeys.includes(h)) ||
        get(nonImageHeaders, 0);

      columns = [];
      let columnsKeys = [];

      spreadsheetData.forEach((d) => {
        const v = get(d, columnKey);
        if (!columnsKeys.includes(v)) {
          columns.push({ label: v, value: v });
          columnsKeys.push(v);
        }
      });
    }

    setBlock({
      ...listBlockData(sheetId),
      columnKey,
      columns,
    });
  };

  const handleTable = (sheetId) => {
    const sheet = savedSpreadsheets.find((s) => s.id === sheetId);

    const config = get(sheet, ["field_data", "config"], {});

    const typeMap = {
      DateTimePicker: "date",
      Checkbox: "boolean",
      ImageUpload: "image",
    };

    let columnData = {};
    Object.keys(config).forEach((k) => {
      const component = get(config, [k, "componentId"]);
      const columnType = get(typeMap, component);
      if (columnType) {
        columnData[k] = { columnType };
      }
    });

    const data = {
      ...activeBlock,
      label: get(sheet, "title"),
      spreadsheet: sheetId,
      columnData: { config: columnData },
    };

    setBlock(data);
  };

  // THIS IS FOR THE DATA GRID ONLY
  // UPDATE MULTIPLE RECORDS
  // export const updateRecords = ({ spreadsheetId, updatedRows }) => {
  //   const body = {
  //     spreadsheet_id: spreadsheetId,
  //     updated_rows: updatedRows,
  //     method: "UPDATE_MULTIPLE_ROWS",
  //     domain: getCurrentDomain(),
  //   };

  //   return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
  //     const data = get(response, ["data", "data"], []);
  //     return data;
  //   });
  // };

  // CREATE MULTIPLE RECORDS - AI VIEW
  const createMultipleRecords = ({ sheetId, rows }) => {
    const body = {
      spreadsheet_id: sheetId,
      method: "CREATE_MULTIPLE_ROWS",
      rows,
      domain: getCurrentDomain(),
    };

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      const newRecords = get(response, "data", []);

      updateMatchingSheets({ type: "create_multiple", sheetId, newRecords });

      return newRecords;
    });
  };

  // UPDATE AND RETREIVE CELLS
  const updateAndRetreive = ({ spreadsheetId, updates, results }) => {
    const body = {
      spreadsheet_id: spreadsheetId,
      updates,
      results,
      method: "UPDATE_AND_RETREIVE",
      domain: getCurrentDomain(),
    };

    return apiRequest.post(getGoogleSheetsEndpoint(), body).then((response) => {
      const data = get(response, ["data"], {});
      return data;
    });
  };

  return {
    //
    handleTable,
    handleGrid,
    handleCustom,
    handleForm,
    handleInfoList,
    handleKanban,
    handleCalendar,
    handleChart,
    handleStat,
    //
    refreshSheet,
    getRecords,
    getRecord,
    createRecord,
    createMultipleRecords,
    updateRecord,
    deleteRecord,
    updateAndRetreive,
  };
};

export default useSpreadsheetRequests;
